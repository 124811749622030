.page-main, .footer-main, .header-main, .cookie, #notice-cookie-block, .modals-wrapper{
  .secondary {
    @include flex;
    align-items: center;
  }
  .action {
    @include fontDefault;
    cursor: pointer;

    &.primary,
    &.tocart.primary,
    &.allow {
      padding-top: 0.4rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 2.3rem;
      margin-right: 2rem;
      color: $colorWhite;
      background-color: $colorBlack;
      @include defaultFastTrans;
      @include font20;
      margin: 0.2rem;
      border: 1px solid black;
      &:hover {
        @include defaultFastTrans;
        box-shadow: 0 0 1px 1px $colorBlack;
      }
      &.tocart.disabled {
        border-color: $colorSuccess;
        background-color: $colorSuccess;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 1.7rem !important;
        line-height: 2.4rem !important;
        padding-top: 0.4rem !important;
        padding-bottom: 0.6rem !important;
        padding-right: 1.7rem !important;
        padding-left: 1.7rem !important;
      }
    }
    &.secondary, &.action-edit-address , &.action.edit-address-link, &.action-select-shipping-item, &.back, &.remind, &.tocart, &.reload, &.clear, &.update, &.continue, &.action-hide-popup, &.action-show-popup, &.action-update, &.action-cancel, &.share {
      @include defaultFastTrans;
      @include flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 2.3rem;
      margin-right: 2rem;
      color: $colorBlack;
      background-color: transparent;
      border: 0.1rem solid black;
      margin: 0.1rem;
      display: inline-block;
      text-align: center;
      align-items: flex-start;
      @include defaultFastTrans;
      &:hover {
        box-shadow: 0 0 1px 1px $colorBlack;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 1.7rem !important;
        line-height: 2.4rem !important;
        padding-top: 0.4rem !important;
        padding-bottom: 0.6rem !important;
        padding-right: 1.7rem !important;
        padding-left: 1.7rem !important;
      }
    }
    &.tocategory.secondary, &.totag.secondary {
      @include defaultFastTrans;
      padding-right: 1.2rem;
      padding-left: 1.2rem;
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      display: inline-block;
      border-radius: 2.3rem;
      border: 0.1rem solid $colorWhite;
      margin: 0.1rem;
      color: white;
      &:hover {
        @include defaultFastTrans;
        box-shadow: 0 0 1px 1px $colorWhite;
      }

    }
    .btnSecondary {
      @include defaultFastTrans;
      padding-right: 1.2rem;
      padding-left: 1.2rem;
      padding-top: 0.1rem;
      padding-bottom: 0.2rem;
      border-radius: 2.3rem;
      margin-right: 0;
      margin-left: 2rem;
      border: 0.1rem solid $colorLightGrey;
      @include fontSmaller;
      margin: 0.1rem;
      &:hover {
        border: 0;
        @include defaultFastTrans;
        box-shadow: 0 0 1px 1px $colorLightGrey;
      }
    }
  }
}
#minicart-content-wrapper .secondary .action, a.action.print, .block.block-dashboard-orders .block-title.order a.action.view {
  @include defaultFastTrans;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;;
  border-radius: 2.3rem;
  margin-right: 2rem;
  color: $colorBlack;
  background-color: transparent;
  border: 0.1rem solid black;
  @include defaultFastTrans;
  &:hover {
    border: 0.2rem solid black;
  }
}
.block.block-dashboard-orders .block-title.order a.action.view {
  position: absolute;
  right: 0;
  bottom: 1.5rem;
  margin-right: 0;
}
.emptyButton {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  margin-left: 0.1rem;
  &:hover {
    margin-left: 0rem;
    border: none;
  }
}


.top-container .overlay .action{
  display: inline-block;
  margin-top: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.1rem;
  color: $colorWhite;
  padding: 1.4rem 2rem;
  border: 0.2rem solid $colorWhite;
  background-color: transparent;
  margin: 0.2rem;
  &:hover {
    margin: 0;
    background-color: $colorWhite;
  }
}
.page-main  .actions-toolbar {
  display: flex;
  > .primary {
    margin-right: 1.5rem;
  }
}
.page-main .action.delete, .page-main .action-delete, .page-header .action.delete {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    background-image: url($iconDir + "trash-light.svg");
    @include icon;
    width: 1.7rem;
    height: 1.7rem;
  }
}
.page-main .action.edit, .page-main .action-edit {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    background-image: url($iconDir + "pencil-light.svg");
    @include icon;
    width: 1.7rem;
    height: 1.7rem;
  }
}
.page-main .action.change-password, .page-main .action-change-password {
  text-decoration: none;
  max-width: inherit;
  min-height: inherit;
  margin: 0;
  padding: 0;
  border: none;
  color: white;
  width: 3rem;
  text-align: right;
  > span {
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;
  }
  &:before {
    background-image: url($iconDir + "key-light.svg");
    @include icon;
    width: 1.7rem;
    height: 1.7rem;
  }
}

.widget.magento-widget.category-overview .widget-content {
  margin: 0 auto;
  display: flex;
  padding-bottom: 12rem;
  padding-top: 10rem;
  justify-content: center;
  a {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
input.action.submit.secondary:hover {
  margin: 0 0.1rem;
}

.product-info-category {
  ul li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }
    }
  }

}
ul.categories.list {
  li a.action.secondary {
    &.tocategory {
      font-size: 1.4rem !important;
      line-height: 2rem !important;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
      }

    }
  }
}

a.button {
  background-color: #b7d2b1;
  padding: 10px 15px;
  @include fontLarge;
  font-weight: bold;
  margin-top: 20px;
  display: inline-block;
}