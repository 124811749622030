input, select, textarea {
  @include fontDefault;
  background: $colorWhite;
  box-shadow: 0;
  border: 1px solid $colorBlack;
  @include fontSmall;
  padding: 0.3rem;
  padding-left: 0.7rem;
  width: 100%;
  &:disabled {
    opacity: 0.5;
  }
}
fieldset {
  border: none;
}
.field.region {
  display: none;
}
img.captcha-img {
  height: 44px;
  margin-right: 1.5rem;
}
fieldset br {
  display: none;
}
table {
  border-spacing: 0 !important;
  width: 100%;
}
body.account .columns .column.main .choice {
  display: flex;
  flex-direction: row;
  align-items: center;
}
input.checkbox {
  margin-right: 0.5rem;
  width: 1.3rem;
  height: 1.3rem;
  margin-top: -3px;
}
.field.password-info {
  margin-bottom: 1.5rem;
}
/*Contact*/
form {
  fieldset {
    padding-bottom: 1.5rem;
    &:after {
      margin: 3.5rem 0 0;
      content: attr(data-hasrequired);
      color: $colorError;
      @include fontSmall;
    }
    .field.note {
      padding-bottom: 1rem;
    }
    .control {
      input {
        width: 100%;
        margin-bottom: 1rem;
      }
      input#captcha_user_login {
        text-transform: none;
      }
      textarea {
        width: 100%;
        padding: 1rem;
      }
    }
  }
}

/*neue adresse tooltip checkout*/
.control._with-tooltip .field-tooltip.toggle #tooltip-label {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.control._with-tooltip .field-tooltip.toggle #tooltip:before {
  font-family: Font Awesome\ 5 Pro;
  font-weight: 400;
  font-size: 2rem;
  color: #333;
  content: "";
}
fieldset.field.street.admin__control-fields.required {
  padding-bottom: 0;
}
fieldset.field.street.admin__control-fields.required > .label {
  display: none;
  padding-bottom: 0;
}

.control._with-tooltip {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.control._with-tooltip .field-tooltip .field-tooltip-content {
  background: $colorLighterGrey;
  border: .1rem solid $colorBlack;
  border-radius: .1rem;
  font-size: 1.4rem;
  padding: 1.2rem;
  width: 27rem;
  display: none;
  margin-left: 4rem;
  position: absolute;
  text-transform: none;
  right: 3.5rem;
  bottom: 0;
  word-wrap: break-word;
  z-index: 2;
}
#opc-new-shipping-address .field.choice {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  line-height: 1;
  align-items: center;
}
#opc-new-shipping-address #shipping-save-in-address-book {
  width: 25px;
  margin-bottom: 0;
}
#opc-new-shipping-address .field.choice label.label {
  line-height: 1;
}
.control._with-tooltip .field-tooltip {
  cursor: pointer;
}
.control._with-tooltip .field-tooltip.toggle #tooltip-label {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.control._with-tooltip .field-tooltip._active .field-tooltip-content {
  display: block;
}
.control._with-tooltip .input-text {
  -ms-flex-preferred-size: 94%;
  flex-basis: 94%;
}
#opc-new-shipping-address input, #opc-new-shipping-address select {
  width: 100%;
  margin-bottom: 10px;
}
.required > label, ._required > label {
  position: relative;
  &:after {
    @include fontIcon;
    content: "#{$iconAsterix}";
    color: $colorError;
    position: absolute;
    top: -15px;
    font-size: 0.8rem;
  }
}

.towishlist {
  position: absolute;
  right: 1.1rem;
  top:1.1rem;
  &:before {
    background-image: url($iconDir + "star_outline.svg");
    @include icon;
    width: 1.7rem;
    height: 1.7rem;
  }
}
.is_new {
  position: absolute;
  left: 1rem;
  top: 1rem;

  &:before {
    background-image: url($iconDir + "new_big.svg");
    @include icon;
    width: 4.2rem;
    height: 4.2rem;
  }
}

.spiceness_level {
  position: absolute;
  left: 1rem;
  top:1rem;
  &.level-1 {
    &:before {
      background-image: url($iconDir + "chili1.svg");
      @include icon;
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  &.level-2 {
    &:before {
      background-image: url($iconDir + "chili2.svg");
      @include icon;
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  &.level-3 {
    &:before {
      background-image: url($iconDir + "chili3.svg");
      @include icon;
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  &.level-4 {
    &:before {
      background-image: url($iconDir + "chili4.svg");
      @include icon;
      width: 4.2rem;
      height: 4.2rem;
    }
  }
  &.level-5 {
    &:before {
      background-image: url($iconDir + "chili5.svg");
      @include icon;
      width: 4.2rem;
      height: 4.2rem;
    }
  }
}
.tocompare {
  &:before {
    content: "#{$iconCompare}";
    @include fontIcon;
    margin-right: 0.25rem;
  }
}
.captcha {
  flex-wrap: wrap;
  .control.captcha {
    width: 100%;
    .captcha-image {
      @include flex;
    }
  }
}

.widget.elementor-widget.contact-form {
 margin-bottom: -12rem !important;
  .field-wrapper.half {
    @include flexBetween;
    width: 100%;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 0 !important;
    }
    .field {
      width: 49%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }
  ul li.field-wrapper {
    width: 100% !important;
  }
  span.wpcf7-list-item {
    width: 100%;
    display: inline-flex;
    align-items: center;

  margin-right: 1rem;
    margin-left: 0;
    input[type="checkbox"] {
      width: 25px;
      text-align: left !important;
    }
  }
  .field.privacy-policy {
    label {
      display: block;
      a {
        text-decoration: underline;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: -6rem !important;
  }
}

.widget.elementor-widget.contact-form ul li:nth-child(5) {
  margin-top: 0 !important;
  margin-bottom: 3rem !important;
}