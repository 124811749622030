.elementor-widget.text-table {
  max-width: 100rem;
  margin: 10rem auto;
  ul {
    li {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;

      &.header{
        background-color: $colorPrimary;
      }

      span {
        width:calc(100% / 4);
        padding:0 1rem;
      }

      &:nth-child(even){
        background-color: $colorGrey;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    ul {
      li {
        span {
          width:calc(100% / 2);
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    ul {
      li {
        span {
          width:100%;
        }
      }
    }
  }
}
