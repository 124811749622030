.elementor-widget.tag-slider, .category-related  {
  position: relative;
  max-width: 134rem;
  margin: 0 auto;
  margin-bottom: 19rem;
  &:last-of-type {
    margin-bottom: 7rem;
  }
  .widget-title {
    margin-bottom: 3.3rem;
  }
  .widget-content {
    margin-top: inherit;
  }
  h3 {
    position: absolute;
    right: 0;
    top: 1.8rem;
  }
  a.action {
    @include fontDefault;
    color: $colorWhite;
    line-height: 2.9rem;
    text-align: right;
    text-decoration: underline;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
    text-decoration: none;
    padding-top: 0.5rem;
    padding-bottom: 0.6rem;
    &:hover {
      background-color: transparent;
    }
  }
  .splide__slide{
    max-width: 42rem;
    width: 100%;
    display: inline-block;
  }
  li {
    &:before {
      position: absolute;
      content: '';
      background-color: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 42rem;
    }
    img {
      max-width: 42rem;
      width: 100%;
    }
    a {
      color: white !important;
      border-color: white !important;
      &:hover {
        box-shadow: 0 0 1px 1px white !important;
      }
    }
  }
  button.splide__arrow.splide__arrow--next {
    right: 0;
  }
  button.splide__arrow.splide__arrow--prev {
    right: 6rem;
  }
  @media only screen and (max-width: 768px) {
    .splide__slide {
      max-width: inherit;
      display: inherit;
      margin: 0 auto !important;
      text-align: center;
      @include flexCenter;
      img {
        text-align: center;
      }
    }
    li:before {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 12rem;
  }
}

