@mixin rotateCCW10 {
  -moz-transform: rotate(-10deg);
  -webkit-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
@mixin rotateCCW15 {
  -moz-transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
@mixin defaultTrans {
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
@mixin shadow {
  box-shadow: 0 0 4px 0 $colorLighterGrey;
}
@mixin defaultFastTrans {
  transition: all 250ms ease;
}
@mixin transition {
  transition: all 500ms ease;
}
@mixin flex {
  display: flex;
}
@mixin flexWrap {
  @include flex;
  flex-wrap: wrap;
}
@mixin flexCenter {
  @include flex;
  align-items: center;
  justify-content: center;
}
@mixin flexBetween {
  @include flex;
  justify-content: space-between;
}
@mixin flexEnd {
  @include flex;
  justify-content: flex-end;
}
@mixin flexStart {
  display: flex;
  align-items: flex-start;
}
@mixin background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@mixin icon {
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
  color: $colorBlack;
  content: '';
}
@mixin border1 {
  border: 1px solid #c8c8c8;
}

@mixin border2 {
  border: 1px solid #f4f4f4;
}

/*Gewürzbilder*/
a.spices{
  background-image: url($iconDir + "gewürze.svg");
  @include icon;
  width: 3rem;
  height: 3.5rem;
}
a.vinegar-oil{
  background-image: url($iconDir + "essingoel.svg");
  @include icon;
  width: 3rem;
  height: 3.5rem;
}
a.spice-mixtures {
  background-image: url($iconDir + "gewürzmischungen.svg");
  @include icon;
  width: 4.8rem;
  height: 3.5rem;
}
a.salts {
  background-image: url($iconDir + "salze.svg");
  @include icon;
  width: 3.3rem;
  height: 3.5rem;
}
a.sauces-soups {
  background-image: url($iconDir + "saucensuppen.svg");
  @include icon;
  height: 3.5rem;
}
a.organic-assortment {
  background-image: url($iconDir + "bio.svg");
  @include icon;
  width: 3.3rem;
  height: 3.5rem;
}
a.accessories {
  background-image: url($iconDir + "zubehoer.svg");
  @include icon;
  width: 3rem;
  height: 3.5rem;
}
a.surprise{
  background-image: url($iconDir + "surprise.svg");
  @include icon;
  width: 3rem;
  height: 3.5rem;
}
a.packs{
  background-image: url($iconDir + "packs.svg");
  @include icon;
  width: 3rem;
  height: 3.5rem;
}
.question-mark > {
  a {
    &:before {
      @extend a.surprise;
    }
  }
}
.packs > {
  a {
    &:before {
      @extend a.packs;
    }
  }
}
.spices >, .product-info-category-main .herbs >, .product-info-category-main .chilis >, .product-info-category-main .pepper >, .product-info-category-main .raw-spices >,  {
  a {
    &:before {
      @extend a.spices;
    }
  }
}
.vinegar-oil > {
  a {
    &:before {
      @extend a.vinegar-oil;
    }
  }
}
.spice-mixtures >, .product-info-category-main .currys >, .product-info-category-main .bbq >, .product-info-category-main .near-far >, .product-info-category-main .sweets > {
  a {
    &:before {
      @extend a.spice-mixtures;
    }
  }
}
.salts, .pure-salts >, .product-info-category-main .pure-salts >, .product-info-category-main .salt-spices > {
  a {
    &:before {
      @extend a.salts;
    }
  }
}
.sauces-soups > {
  a {
    &:before {
      @extend a.sauces-soups;
    }
  }
}
.organic-assortment > {
  a {
    &:before {
      @extend a.organic-assortment;
    }
  }
}
.accessories > {
  a {
    &:before {
      @extend a.accessories;
    }
  }
}
/* span:before inside of content.scss search for .widget-content */