.post-slider {
  width: 100%;
  max-width: 134rem;
  margin: 0 auto;
  margin-top: 10rem;
  margin-bottom: 19rem;
  &:last-of-type {
    margin-bottom: 7rem;
  }
  .widget-title {
    margin-bottom: 3rem;
  }

  .widget-content {
    margin: 0 auto;

    .splide {
      max-width: 134rem;
      width: 100% !important;
      margin: 0 auto;

      .splide__list {
        width: 100% !important;

        li {
          @include flexCenter;

          .overlay {
            top: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0 auto;
          }

          .overlay {
            max-width: 30.5rem;
            opacity: 0;
            @include defaultTrans;
            @include flexCenter;
            flex-direction: column;
            margin: 0 auto;

            &:hover {
              opacity: 1;
              background-color: rgba(255, 255, 255, 0.85);
              @include defaultTrans;
            }

            ul.post-tags {
              @include fontSmall;
              @include flexCenter;
              flex-direction: row;
              text-align: center;

              li {
                width: 100% !important;
              }
            }

            h3 {
              margin-bottom: 3rem;
            }

            a {
              color: black;
              border-color: black;
              display: inline-flex;

              &:hover {
                box-shadow: 0 0 1px 1px black;
              }
            }
          }
        }
      }
    }

    > .slider {
      width: 100% !important;
      @include flex;

      > li {
        position: relative;
        max-width: 30.5rem;
        margin-left: 2rem;
        margin-right: 2rem;

        .overlay {
          top: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
        }

        .overlay {
          max-width: 30.5rem;
          opacity: 0;
          @include defaultTrans;
          @include flexCenter;
          flex-direction: column;

          &:hover {
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.85);
            @include defaultTrans;
          }

          ul.post-tags {
            @include fontSmall;
            @include flexCenter;
            flex-direction: row;
            text-align: center;

            li {
              margin-left: 0.25rem;
              margin-right: 0.25rem;
              width: 100% !important;
            }
          }

          h3 {
            margin-bottom: 3rem;
          }

          a {
            color: black;
            border-color: black;
            display: inline-flex;

            &:hover {
              box-shadow: 0 0 1px 1px black;
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        > li {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 4.5rem;

          .overlay {
            position: inherit;
            opacity: 1;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .splide__track {
      margin-left: 3.3rem !important;
      margin-right: 3.3rem !important;
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: 16rem;
    &:last-of-type {
      margin-bottom: 2rem;
    }

  }
  @media only screen and (max-width: 480px) {
    margin-top: 8rem;
  }
}
.elementor-widget-container .post-slider {
  margin-bottom: 19rem !important;
}
