#notice-cookie-block{
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  margin: 0;
  background-color: rgba(0,0,0,0.5);
  max-width: none;
  padding: 0;
  border: 0;
  height: 100%;
  @include flexCenter;
  align-items: center;
  .content {
    background-color: $colorWhite;
    border: 0.2rem solid $colorPrimary;
    max-width: 80rem;
    width:100%;
    padding: 2rem;
    margin: 2rem;
    .actions {
      min-width: 22rem;
      padding-top: 2rem;
      .disallow {
        border: 0;
        font-size: 1.4rem;
        box-shadow: none !important;
        cursor: text;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .content {
      flex-wrap: wrap;
      .actions {
        padding-top: 1rem;
      }
    }
  }
}
