.page-print {
  .page-wrapper .page-main .page-title-wrapper .page-title {
    font-size: 2.4rem;
    padding-bottom: 1rem !important;
    line-height: 3.6rem;
  }
  .block-content {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .page-wrapper {
    margin-top: 5rem;
    .page-main {
      padding-top: 0;

      .logo {
        width: 100%;
        @include flexCenter;
      }

      .columns {
        max-width: 75rem;

        .column {
          width: 100%;
          .table-wrapper.order-items {
            table {
              font-size: 1.6rem;
              tr {

                td, th {
                  display: block;

                  &.price {
                    padding-left: 0 !important;
                  }
                  &.sku {
                    width: 15% !important;
                  }
                  &.name {
                    width: 40% !important;
                  }
                  &.qty {
                    width: 15% !important;
                    ul {
                      @include flex;
                      flex-direction: column;
                    }
                  }

                  &.sku {
                    width: 20%;
                  }
                }
              }
            }
          }
        }

        .actions-toolbar {
          display: none;
        }
      }
    }
  }
}