.elementor-widget.text-teaser {
  max-width: $smallContent;
  margin: 0 auto;
  @include fontLarge;
  font-weight: bold;
  margin-bottom: 6rem;
  .widget-content {
    margin-left: -11.5rem;
    margin-top: 0;
    max-width: 42rem;
  }
  &.right {
    .widget-content {
      margin-left: inherit;
      margin-right: -11.5rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    &.right .widget-content {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }
    &.left .widget-content{
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }

  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    &.right .widget-content {
      padding-right: 3.3rem;
      padding-left: 3.3rem;
    }
    &.left .widget-content{
      padding-right: 3.3rem;
      padding-left: 3.3rem;
    }
  }
  @media only screen and (max-width: 480px) {
    &.right .widget-content {
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }
    &.left .widget-content{
      padding-right: 1.6rem;
      padding-left: 1.6rem;
    }
  }
}