.page-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $colorWhite;
  z-index: 3;
  .nav-toggle {
    display: block;
  }
  .header-content {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    .header-marquee {
      padding-left: 1rem;
      padding-right: 1rem;
      overflow: hidden;
      background-color:#F3A598;
      color:black;
      height:30px;

      font-size: 1.4rem;
      line-height: 3rem;

      .block {
        overflow: hidden;

        .marquee {
          & > span {
            display:none;
          }
          span {
            padding-right:100px;
          }
        }
      }
    }
    .header-top {
      border-bottom: 1px solid #C8C8C8;
      padding-left: 5rem;
      padding-right: 5rem;
      .header-top-container {
        @include flexBetween;
        align-items: center;
        height: 4.5rem;
        .section {
          width: 33.3%;
          &.header-top-link {
            a {
              background-color: #b7d2b1;
              padding: 10px 15px;
              font-size: 18px;
            }
          }
          &.header-top-title {
            text-align:left;
          }
          &.header-top-social {
            text-align: right;

            ul {
              justify-content: flex-end;
            }
          }
          ul {
            @include flex;
            align-items: center;
            li {
              a {
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
                font-weight: 700;
                @include fontSmaller;
                text-decoration: underline;
                padding-right: 3rem;
                display: inline-block;
                color: rgba(0, 0, 0, 0.3);
              }
              &.active {
                a {
                  color: black;
                }
              }
            }
          }
        }
        .section.header-top-social {
          @include fontSmaller;

          a {
            color: black;
            padding: 0;
          }

          i {
            font-size: 2rem;
            padding-right: 2rem;
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .header-main {
      padding-left: 5rem;
      padding-right: 5rem;
      border-bottom: 1px solid #C8C8C8;
      .header-main-container {
        position: relative;
        @include flex;
        align-items: center;
        justify-content: space-between;
        > div {
          width: 33.3%;
        }
        .mobile {
          display: none;
        }
        .desktop {
          display: block;
        }
        .header-main-menu {
          .section-item-title {
            display: none;
          }
        }
        .header-main-logo {
          text-align: center;
          @media only screen and (max-width: 768px) {
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .header-main-info {
          padding-top: 1.5rem;
          @include flex;
          justify-content: flex-end;
          > div {
            padding-left: 4.4rem;
          }

        }
        .block-wiki{
          display: inline-block;
          line-height: 3.7rem;
          font-size: 2.4rem;
          font-weight: bold;
        }
        .block-account {
          a {
            @include flex;
            flex-direction: column;
            align-items: center;
            &:before {
              background-image: url($iconDir + "profil.svg");
              @include icon;
              width: 2.4rem;
            }
            span {
              text-decoration: underline;
              font-size: 1.1rem;
              line-height: 1.6rem;
              font-weight: bold;
              padding-top: 0.5rem;
            }
          }
        }
        .section-item-content {
          ul {
            @include flex;
            li {
              @media only screen and (min-width: 1025px) {
                &:hover {
                  .level0.submenu {
                    max-height: 1000px;
                    overflow: inherit;
                  }
                }
              }
              &:first-child {
                > a {
                  padding-left: 0;
                }
              }
              &:last-child {
                a {
                  padding-right: 0;
                }
              }
              > a {
                padding-top: 3.8rem;
                padding-bottom: 3.8rem;
                display: inline-block;
                @include fontLarge;
                padding-left: 3rem;
                padding-right: 3rem;
                font-weight: bold;

              }
              &.parent:hover {
                > a {
                  background-image: url($iconDir + "arrow.png");
                  background-position: bottom center;
                  background-repeat: no-repeat;
                  @media only screen and (max-width: 1024px) {
                    background: none;
                  }
                }
              }
              @media only screen and (max-width: 1024px) {
                &.parent.active > a{
                  background-image: url($iconDir + "arrow.png");
                  background-position: bottom center;
                  background-repeat: no-repeat;
                }
              }
            }
            .level0.submenu {
              background-color: #F4F4F4;
              position: absolute;
              left: 0;
              right: 0;
              max-height: 0;
              overflow: hidden;
              padding-left: 6rem;
              padding-right: 6rem;
              justify-content: center;
              max-width: 144rem;
              margin: 0 auto;
              margin-left: -5rem;
              margin-right: -5rem;
              z-index: 99999999;
              > li {
                opacity: 20%;
                > a {
                  min-height: 14.5rem;
                  font-size: 2rem;
                  color: black;
                  padding-right: 4rem;
                  padding-left: 4rem;
                  @include flexCenter;
                  flex-direction: column;
                }
                &.active {
                  opacity: 1;
                  a {
                    color: $colorBlack;
                  }
                }
                &:first-child {
                  a {
                    padding-left: 0;
                  }
                }
                &:last-child {
                  a {
                    padding-right: 0;
                  }
                }
                @media only screen and (min-width: 1025px) {
                  &:hover {
                    opacity: 1;
                    .level1.submenu {
                      overflow: inherit;
                      max-height: 1000px;
                      background: $colorLightGrey;
                    }
                  }
                }
              }
            }
            .level1.submenu {
              position: absolute;
              left: 0;
              right: 0;
              max-height: 0;
              overflow: hidden;
              li {
                width: 100%;
                text-align: center;
                a {
                  padding-top: 2.1rem;
                  padding-bottom: 2rem;
                  font-size: 2rem;
                  text-align: center;
                  line-height: 2.9rem;
                  display: block;
                }
                &.chilis {
                  background-color: $colorChilis;
                }
                &.herbs {
                  background-color: $colorHerbs;
                }
                &.pepper {
                  background-color: $colorPepper;
                }
                &.raw-spices {
                  background-color: $colorRawspices;
                }
                &.bbq {
                  background-color: $colorBBQdown;
                }
                &.currys {
                  background-color: $colorCurrys;
                }
                &.near-far {
                  background-color: $colorNearfar;
                }
                &.sweets {
                  background-color: $colorSweets;
                }
                &.pure-salts {
                  background-color: $colorPuresalt;
                }
                &.salts {
                  background-color: $colorRosesalt;
                }
                &.salt-spices {
                  background-color: $colorSaltspice;
                }
                &.sauces {
                  background-color: $colorSauces;
                }
                &.soups {
                  background-color: $colorSoups;
                }
                &.organic-assortment {
                  background-color: $colorBio;
                }
                &.oil{
                  background-color: $colorOil;
                }
                &.vinegar{
                  background-color: $colorVinegar;
                }
                &.sixpacks{
                  background-color: $colorSixPack;
                }
                &.trypacks{
                  background-color: $colorTryPack;
                }
              }
              .level2 {
                a:before {
                  display: none;
                }
              }
            }
          }
        }
        @media only screen and (max-width: 1024px) {
          .desktop {
            display: none;
          }
          .mobile {
            display: block;
            .nav-sections {
              position: absolute;
              top: 9.5rem;
              background-color: white;
              width: 100%;
              display: none;
              left: 0;
              padding-left: 0rem;
              padding-right: 0rem;
              box-shadow: 0px 1px 1px 1px #F4F4F4;
              a {
                font-size: 2rem;
              }
              &.active {
                display: block;
              }
              &.active + .action {
                &:before {
                  transform: rotate(45deg);
                  left: 5px;
                  top: 0.7rem;
                  background: $colorBlack;
                }
                span {
                  left: -50px;
                  opacity: 0;
                  background: $colorBlack;
                }
                &:after {
                  transform: rotate(-45deg);
                  left: 5px;
                  top: 0.7rem;
                  background: $colorBlack;
                }
              }
              #store-mobile-info {
                position: relative;
                @include flexCenter;
                padding-bottom: 2.5rem;
                &:after {
                  content: '';
                  border-bottom: 0.1rem solid #C8C8C8;
                  width: 100%;
                  position: absolute;
                  bottom: 0;
                }
                .block.block-search, .block.block-account{
                  display: block;
                  padding-right: 2.5rem;
                  padding-left: 2.5rem;
                    .form {
                      bottom: -7rem;
                    }
                }
              }
              #store-mobile-meta {
                @include flexBetween;
                height: 6.5rem;
                align-items: center;
                justify-content: flex-end;
                .section.mobile-meta-social {
                  font-weight: 700;
                  font-size: 1.4rem;
                  text-decoration: underline;
                  display: inline-block;
                  ul {
                    align-items: center;
                    li {
                      a {
                        font-weight: 700;
                        font-size: 2rem;
                        text-decoration: underline;
                        padding-right: 1.4rem;
                        display: inline-block;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 0;
                      }
                    }
                  }
                }
                .section.mobile-meta-language {
                  ul {
                    li {
                      a {
                        font-weight: 700;
                        font-size: 1.4rem;
                        text-decoration: underline;
                        padding-right: 1.4rem;
                        display: inline-block;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 0;
                      }
                    }
                  }
                }
              }
              #store-mobile-menu {
                .navigation {
                  .mainmenu {
                    justify-content: center;
                  }
                  .submenus {
                    display: block;
                    margin-bottom: 2rem;
                    li.level0 {
                      /*max-height: 0;
                      overflow: hidden;
                      @include defaultTrans;*/
                      width: 100%;
                      margin-left: 0rem;
                      margin-right: 0rem;
                      padding: 0;
                      opacity: 1;
                      position: inherit;
                      max-height: 100rem;
                      &.active {
                        max-height: 100rem;
                        @include defaultTrans;
                        ul.level0 {
                          @include flex;
                          position: inherit;
                          flex-direction: column;
                          max-height: 100rem;
                          padding-left: 0;
                          padding-right: 0;
                          margin: 0 auto;
                          width: 100%;
                          @include defaultTrans;
                          > li.level1 {
                            opacity: 1;
                            > a {
                              width: 100%;
                              padding-left: 0;
                              padding-right: 0;
                              padding-bottom: 0;
                              padding-top: 2rem;
                              padding-bottom: 2rem;
                              min-height: 6rem;
                            }
                            &:last-child a {
                              padding-bottom: 4rem;
                            }
                            &:first-child a {
                              padding-top: 4rem;
                            }
                            &.active {
                              ul.level1 {
                                max-height: 100rem;
                                @include defaultTrans;
                              }
                            }
                            ul.level1 {
                              position: inherit;
                              @include flex;
                              flex-direction: column;
                              li.level2 {
                                a {
                                  padding-left: 0;
                                  padding-right: 0;
                                  padding-bottom: 1rem;
                                  padding-top: 1rem;

                                }
                              }
                            }
                          }
                        }
                      }
                      &.nav-2 {
                        ul.level0 {
                          li.level1 {
                            a {
                              padding-top: 1rem;
                              padding-bottom: 1rem;
                            }
                            &:first-child a {
                              padding-top: 3rem;
                              padding-bottom: 1rem;
                            }
                            &:last-child a {
                              padding-top: 1rem;
                              padding-bottom: 3rem;
                            }
                          }

                        }
                      }
                    }
                  }
                }
              }
            }
            .action.nav-toggle {
              width: 50px;
              height: 1.4rem;
              position: relative;
              display: inline-block;
              &:before, &:after, span {
                background: $colorBlack;
                content: '';
                display: block;
                width: 1.8rem;
                height: 0.3rem;
                position: absolute;
                left: 0;
                @include defaultTrans;
                transition-property: transform, top, bottom, left, opacity, background;
              }
              &:before {
                top: 0;
              }
              span {
                top: 0.7rem;
              }
              &:after {
                top: 1.4rem;
              }
            }
          }
        }
        @media only screen and (max-width: 480px) {
          .mobile {
            display: block;

            .nav-sections {
              &.active {
                padding-left: 0rem;
                padding-right: 0rem;
              }
              #store-mobile-info:after {
                margin-left: 0rem;
                margin-right: 0rem;
                width: 100%;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        border-bottom: 0px;
        padding-top: 2.3rem;
        padding-bottom: 2.3rem;
        .block.block-account,
        .block.block-search,
        .block.block-wiki {
          display: none;
        }
        .header-main-info {
          padding-top: 0 !important;
        }
        .minicart-wrapper .action.showcart .subtotal {
          display: none;
        }
      }
      @media only screen and (max-width: 1024px) {
        .header-main-container {
          position: inherit;
        }
      }
    }
  }
}
