.additional-products {
  margin-top: 6.5rem;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 1280px){
    order: 0;
  }
  @media only screen and (max-width: 1024px){
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product-item-info .product.details.product-item-details {
       flex-wrap: wrap;
     }
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product.container.product-item-container {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 900px){
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product-item-info .product.actions.product-item-actions {
      margin-bottom: 2.5rem;
      width: 100% !important;
      margin-top: 1rem;
    }
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product-item-info .product.details.product-item-details {
      padding-top: 2.5rem;
      padding-right: 2.5rem;
    }
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product.container.product-item-container {
      max-width: 100% !important;
    }
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product-item-info .product.image.product-item-image {
      padding-left: 2.5rem !important;
    }
  }
  @media only screen and (max-width: 620px){
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product-item-info {
      flex-wrap: wrap;
    }
    .products.wrapper.list.products-list ol.products.list.items.product-items li.item.product.product-item .product-item-info .product.details.product-item-details {
      padding-top: 0;
      width: 100% !important;
    }
  }
  .products.wrapper.list.products-list {
    ol.products.list.items.product-items {
      li.item.product.product-item {
        .product.container.product-item-container {
          max-width: 60%;
        }
        .product-item-info {
          @include flex;
          border-bottom: 1px solid #000;
          background-color: #F4F4F4;
          .product.image.product-item-image {
            span {
              display: block;
              padding-bottom: 0 !important;
            }
            img {
              max-width: 20rem;
            }
          }
          .product.actions.product-item-actions {
            margin-right: 2.5rem;
            width: 19.5rem;
          }

          .product.description.product-item-description {
            font-size: 16px;
            line-height: 2.4rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            strong {
              font-weight: normal;
            }
          }
          .product.image.product-item-image {
            padding-left: 3.5rem;
          }
          .product.details.product-item-details {
            width: calc(100% - 20rem);
            align-items: center;
            @include flex;
            justify-content: space-between;
            padding-left: 2.5rem;
            span.price {
              font-size: 2rem;
            }
            .product.price.product-item-price {
              display: flex;
              .price-details {
                padding-left: 0.5rem;
                font-size: 1rem;
                display: flex;
                align-items: flex-end;
              }
              span.tax-details, span.shipping-details {
                font-size: 1rem;
                font-size: 1rem;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }
}