/*Product Detail*/
.catalog-product-view {
  .columns .column.main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .related{
      margin-top: 7rem;
      width: 100%;
    }
    .product {
      &.media {
        max-width: 48rem;
        width: 33%;
        position: relative;
        margin-bottom: 6rem;
        margin-right: 10rem;

        .action.skip {
          display: none;
        }

        .gallery-placeholder div {
          max-height: 446px;
        }

        .fotorama {
          &__nav {
            &-wrap.fotorama__nav-wrap--horizontal {
              position: absolute;
              bottom: 0.9rem;
            }

            &--dots .fotorama__nav__frame {
              height: inherit;
              width: inherit;
            }
          }
          &__dot {
            width: 9px;
            height: 9px;
            top: inherit;
            left: inherit;
            border-radius: 50%;
            margin-left: 1.3rem;
            margin-right: 1.3rem;
          }
          &__active .fotorama__dot {
            background-color: black;
            border-color: black;
          }
          &__fullscreen-icon, &__zoom-out, &__zoom-in, &__arr, &__stage__shaft, &, &, &{
            &:focus:after{
              box-shadow: none !important;
            }
          }
          &__nav__frame {
            &--thumb, &--dot {
              &:focus{
                .fotorama{
                  &__thumb, &__dot{
                    &:after{
                      box-shadow: none !important;
                    }
                  }
                }
              }
            }
          }
          &__stage, &__nav{
            &:before, &:after{
              display: none !important;
            }
          }
        } /* fotorama */
      }
      &-info {
        &-main {
          width: calc(100% - 33% - 10rem);
          margin-top: 0;
          padding-top: 0.8rem;
          line-height: 2.9rem;
          padding-bottom: 6rem;
          .page-title {
            @include fontLargest;
            text-align: left;
            padding-bottom: 0.5rem;
          }
          .product-item-spice_type {
            padding-bottom: 2.5rem;
            strong {
              @include fontLarge;
            }
          }
        }
        &-details {
          @include flexBetween;
          padding-bottom: 2.8rem;
          .shipping-details {
            a {
              text-decoration: underline;
            }
          }
          & > div {
            padding-right: 4.5rem;
          }
          &:last-child {
            @include fontSmaller;
            padding-bottom: 0;
            justify-content: flex-start;
          }
        }
        &-category {
          ul {
            @include flex;
            li {
              a.action.tocategory.secondary {
                margin-right: 2rem;
                border-color: #C8C8C8;
                color: #000;
                &:hover {
                  box-shadow: 0 0 1px 1px #000;
                }
              }
              &:first-child {
                a {

                }
              }
            }
          }
        }
        &-new {
          position: absolute;
          right: 19.5rem;
          top: -5rem;
          background-image: url($iconDir + "new_big.svg");
          @include icon;
          width: 11.5rem;
          height: 11.5rem;
          span {
            display: none;
          }
        }
        &-special-price {
          position: absolute;
          right: 19.5rem;
          top: -5rem;
          background-image: url($iconDir + "sale_big.svg");
          @include icon;
          width: 11.5rem;
          height: 11.5rem;
          span {
            display: none;
          }
        }
        &-icon {
          a {
            @extend .emptyButton;
            color: transparent;
            border-radius: 0;
            span {
              display: none;
            }
          }
        }
        &-price {
          padding-top: 7.2rem;
          .price {
            &-final_price {
              justify-content: flex-start;
            }
            &-box.price-final_price {
              @include fontLarger;
              text-align: left;
              justify-content: flex-start;
              .percentage {
                margin-right: 2rem;
              }
            }
            &-details {
              display: none;
            }
          }
        }
      }
      &-add-form {
        padding-right: 4.5rem;
        .box-tocart {
          padding-bottom: 4.5rem;
          .fieldset {
            width: 100%;
            @include flex;
            .field.spice_amount {
              width: calc(100% - 32rem);
            }
            .field.qty {
              width: 10rem;
              @include flexCenter;
              label {
                display: none;
              }
              .control {
                @include flex;
                align-items: center;
                .remove, .add{
                  cursor: pointer;
                  text-decoration: none;
                  text-align: center;
                  width: 2.2rem;
                  height: 2.2rem;
                  border: 0.1rem solid $colorBlack;
                  border-radius: 50%;
                  font-size: inherit;
                  line-height: 1;
                  font-weight: normal;
                  color: transparent;
                }
                .remove {
                  background-image: url($iconDir + "minus.png");
                  @include background;
                  background-size: 1rem;
                }
                .add {
                  background-image: url($iconDir + "plus.png");
                  @include background;
                  background-size: 1rem;
                }
                .input-text.qty {
                  width: 4.8rem;
                  text-align: center;
                  text-decoration: none;
                  font-size: 2rem;
                  line-height: 2.9rem;
                  border: none;
                }
              }
            }
            .actions {
              margin-left: 4rem;
              min-width: 19rem;
              min-height: 5rem;
              @include flexEnd;
            }
          }
        }
      }
      &.detailed {
        width: 100%;
        .items {
          position: relative;
          .item {
            &.title {
              float: left;
              width: 25%;
              a {
                display: block;
                position: relative;
                z-index: 2;
                min-height: 7.3rem;
                border-right: 0.1rem solid $colorLightGrey;
                border-top: 0.1rem solid $colorLightGrey;
                box-sizing: border-box;
                cursor: pointer;
                padding-top: 2.3rem;
                text-align: center;
                padding-bottom: 1.6rem;
              }
              &.active {
                a {
                  font-weight: bold;
                  padding-bottom: 1.8rem;
                  border-bottom: 2px solid white;
                  border-left: 0;
                }
                &+.content .inner-content {
                  opacity: 1;
                }
              }
              &:first-child a {
                border-right: 0.1rem solid $colorLightGrey;
                border-left: 0.1rem solid $colorLightGrey;

              }
            }
            &.content {
              float: right;
              margin-left: -100%;
              width: 100%;
              margin-top: 7.3rem;
              padding: 9.5rem;
              border: 0.1rem solid $colorLightGrey;
              &.active {
                display: block;
              }
              .inner-content {
                opacity: 0;
                max-width: 60rem;
                margin: 0 auto;
                &.columns {
                  @include flexBetween;
                  flex-direction: row;
                }
                .left{
                  width: 100%;
                  max-width: 23.6rem;
                  font-weight: bold;
                }
                .right {
                  width: 100%;
                  max-width: 30.5rem;
                  ul {
                    li {
                      @include flexBetween;
                    }
                  }
                }
                @media only screen and (max-width: 600px) {
                  flex-wrap: wrap;
                  .left, .right {
                    max-width: inherit;
                    width: 100%;
                  }
                }
                .inner:not(:last-child){
                  padding-bottom: 2.5rem;
                }
              }
            }
          }
        }
      }
    } /* product */
    .page-title-wrapper {
      padding-top: 0;
    }
    @media only screen and (max-width: 1280px) {
      padding-left: 3.3rem;
      padding-right: 3.3rem;
    }
    @media only screen and (max-width: 1024px) {
      .product {
        &.media, &-info-main {
          width: 100%;
        }
        &.media{
          margin: 0 auto;
        }
        &-info-main {
          .page-title-wrapper.product .page-title {
            padding-left: 0;
            padding-right: 0;
          }
          .product {
            &-info-details {
              > div {
                padding-right: 1rem;
                padding-bottom: 0.4rem;
              }
              .product-info-new{
                right: inherit;
                top: 0;
                left: 3.5rem;
              }
              .product-info-special-price {
                right: inherit;
                top: 0;
                left: 3.5rem;
              }
            }
            &-add-form  {
              padding-right: 0;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .product{
        &.detailed{
          .items{
            .item.content {
              padding: 3rem;
            }
          }
        }
        &-info-details {
          flex-wrap: wrap;
        }
      }
    }
    @media only screen and (max-width: 560px) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      .product {
        &-info {
          &-details {
            font-size: 1rem !important;
            line-height: 1.5rem;
            flex-wrap: wrap;
            padding-bottom: 2rem;
            > div {
              padding-right: 0.5rem;
            }
          }
          &-main {
            padding-bottom: 4.4rem;
            line-height: 2.4rem;
            .page-title {
              font-size: 3rem;
              line-height: 4rem;
            }
          }
          &-price {
            padding-top: 4.5rem;
            .price-box.price-final_price {
              font-size: 2.4rem;
              line-height: 3.4rem;
            }
          }
        }
        &-add-form{
          .box-tocart {
            .field{
              &set{
                flex-wrap: wrap;
              }
              &.spice_amount {
                width: 100%;
              }
              &.qty {
                justify-content: flex-start;
              }
              &.qty, .actions{
                width: 50%;
                margin-left: 0;
                min-width: inherit;
              }
            }
          }
        }
        &.attribute.sku {
          width: 100%;
          padding-bottom: 0.4rem;
        }
        &.detailed {
          .items {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            border-right: 0.1rem solid #c8c8c8;
            border-top:0.1rem solid #C8C8C8;
            .item {
              &.title {
                order: 1;
                width: 50%;
                &.active a, a {
                  padding-bottom: 0.85rem;
                  padding-top: 0.85rem;
                  min-height: inherit;
                  border: 0.1rem solid #C8C8C8;
                  margin: 0 -1px;
                  border-top: 0;
                }
                &:nth-of-type(5n), &:first-child {
                  &.active a, a {
                    margin: 0;
                  }
                }
              }
              &.content {
                padding-top: 3.8rem;
                order: 2;
                margin: 0;
                border-right: 0;
                z-index: 1;
                margin-top: -1px;
                background-color: white;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 360px) {
      .field.qty {
        width: 40%;
      }
      .actions {
        width: 60%;
      }
    }
  }
}

/*Galerie*/
.gallery-placeholder._block-content-loading {
  background-image: url($imageDir + "loader.gif");
  bottom: 0;
  height: 6.4rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 6.4rem;
  z-index: 100;
}
.gallery-placeholder._block-content-loading .gallery-placeholder__image {
  opacity: 0;
}
.gallery-placeholder__image {
  width: 100%;
}