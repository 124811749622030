/*Account*/
.customer-account-login, .customer-account-create {
  .column.main {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.page-layout-2columns-left {
  .page-main {
    padding-top: 0rem;
  }
}
.customer-account-login {
  h1.page-title {
    padding-bottom: 5rem;
  }
}
.account {
  .page-wrapper .page-main .page-title-wrapper .page-title {
    padding-bottom: 2.5rem;
  }
  .actions-toolbar {
    display: block;
    text-align: left !important;
    margin-top: 1rem;
  }
  .columns {
    .sidebar-main {
      padding-top: 3rem;

      a, strong {
        line-height: 2.9rem;
        display: block;
      }
      a {
        padding-bottom: 1.1rem;
      }
      .current {
        padding-bottom: 1.1rem;
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
       .current, a {
          padding-bottom: 0rem;
        }
      }
      .account-nav {
        padding-left: 2rem;
        padding-right: 2rem;
        @media only screen and (max-width: 1024px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    p.toolbar-amount {
      display: none;
    }
    .order-products-toolbar.toolbar {
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 2rem;
      text-align: right;
      margin: 0;
      select {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 2rem;
        text-align: right;
      }
    }
    .column.main {
      .table-wrapper {
        .table-caption {
          display: none;
        }
        table {
          th {
            text-align: left;
          }
        }
      }
      span.order-status, .order-date, .actions-toolbar.order-actions-toolbar a {
        @include fontSmall;
      }
      .table-wrapper.order-items {
        margin-bottom: 1.5rem;
        tr {
          display: flex;
          justify-content: space-between;
          td, th {
            display: block;
            &.name {
              width: 40%;
            }
            &.sku {
              width: 10%;
            }
            &.price {
              width: 15%;
              padding-left: 4rem;
            }
            &.qty {
              width: 30%;
              ul.items-qty {
                @include flex;
                .item {
                  padding-right: 1rem;
                }
              }
            }
            &.subtotal {
              text-align: right;
              width: 15%;
            }
          }
        }
        tfoot {
          margin-top: 2rem;
          display: block;
        }
      }
      .order-details-items.ordered {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

      }
      ul.items.order-links {
        display: none;
      }
      .limiter {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        @include flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        select#limiter {
          width: 7.5rem;
          margin-left: 0.5rem;
          margin-right: 1rem;
        }
      }
      .box {
        position: relative;

        .box-content {
          padding-bottom: 1.5rem;
        }

        .box-actions {
          position: absolute;
          right: 0;
          top: 0;
          @include flexStart;
          justify-content: space-between;
        }
      }
      .block-title, .legend {
        position: relative;
        width: 100%;
        margin-bottom: 2rem;
        @include fontLarge;
        font-weight: bold;
        border-bottom: 0.1rem solid $colorBlack;
        padding-bottom: 1rem;
      }

      form {
        &.form-address-edit {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          fieldset {
            width: 49%;
          }
          @media only screen and (max-width: 768px) {
            justify-content: center;
            fieldset {
              width: 100%;
            }
          }
        }
      }
      .block.block-dashboard-addresses .block-title {
        .action {
          display: none;
        }
      }
      .block-addresses-default {
        padding-bottom: 2.5rem;
      }
      .block-addresses-list {
        .block-title {
          margin-bottom: 0;
        }
        p.empty {
          margin-top: 1rem;
          margin-bottom: 1rem;
          @include fontSmaller;
        }
      }

      .block-dashboard-orders {
        .block-content {
          > * {
            flex-basis: 100%;
          }
        }

        table {
          width: 100%;
          .table-caption {
            text-align: left;
          }
          th {
            text-align: left;
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
      }
    }
  }
  .wishlist {
    .limiter {
      margin: 0;
    }
    .product-item-info {
      @include flex;
      flex-wrap: wrap;
      > div, strong {
        width: 100%;
      }
      .price-box.price-configured_price {
        font-weight: bold;
      }
      .price-details {
        @include fontSmallest;
        padding-top: 1rem;
      }
      .product-item-inner {
        padding-top: 1.5rem;
        position: relative;
        .field.comment-box label {
          display: none;
        }
        .product-item-comment {
          width: 100%;
          min-height: 150px;
          padding: 1rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .box-tocart {
          .field.qty {
            position: absolute;
            top: -3.6rem;
            right: 0;
            display: flex;
            align-items: center;
          }
          .control {
            margin: 0;
            margin-left: 1rem;
            input {
              width: 5rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .page-wrapper .columns {
      justify-content: flex-end;
      .products.wrapper.grid.products-grid ol, widget.elementor-widget.product-grid ol {
        justify-content: flex-start;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .page-wrapper .columns {
      .products.wrapper.grid.products-grid ol, widget.elementor-widget.product-grid ol {
        justify-content: center;
      }
    }
  }
}
.customer-account-create {
  h1 {
    padding-bottom: 5rem;
  }
  .actions-toolbar {
    display: flex !important;
    width: 100%;
  }
  form#form-validate {
    @include flexWrap;
    flex-direction: row-reverse;
    justify-content: space-between;
    .fieldset {
      width: 49%;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      legend {
        font-weight: bold;
      }
    }
  }
}
.additional-addresses {
  table {
    position: relative;

    th.col.state, td.col.state, th.col.phone, td.col.phone {
      display: none;
    }
    td.col.actions {
      padding-right: 0;
    }
  }
}

/*Login*/
.login-container {
  @include flexBetween;
  align-items: flex-start;
  .block {
    width: 49%;
    .block-content {
      .actions-toolbar {
        margin-top: 1.5rem;
      }
    }
  }
}
/*Passwort*/
.password.required {
  .control {
    display: flex;
    flex-direction: column;
    @include fontSmall;
    #password {
      order: 1;
    }

    #password-error {
      color: $colorError;
      margin: 0.5rem 0;
      order: 3;
    }

    #password-strength-meter-container {
      background-color: $colorSecondary;
      padding: 1rem 0 1rem 1rem;
      order: 2;
      color: $colorWhite;
      margin-bottom: 1rem;
      &.password-very-strong {
        background-color: $colorSuccess;
      }
      &.password-strong {
        background-color: $colorSuccess;
      }
      &.password-medium {
        background-color: $colorInfo;
      }
      &.password-weak {
        background-color: $colorError;
      }
      &.password-error {
        background-color: $colorError;
      }
    }
  }
}