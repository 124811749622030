.elementor-widget.text-list {
  max-width: $smallContent;
  margin: 0 auto;
  margin-bottom: 10rem;
  ul {
    li {
      display: flex;

      &:before {
        content: '·';
        padding-right: 2rem;
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
  &:only-of-type {
    margin-bottom: 10rem;
  }
  h3 {
    text-align: left;
  }
}
