.elementor-widget.instagram-feed {
  margin-left: 5rem;
  margin-right: 5rem;
  .widget-content {
    #sb_instagram {
      width: calc(100% + 4rem) !important;
      #sbi_images {
        @include flex;
        /*margin-bottom: 14rem;*/
        margin-left: -2rem;
        margin-right: -2rem;
        .sbi_item {
          max-width: 19rem;
          width: 18% !important;
          margin-bottom: 3rem !important;
          padding-bottom: 0 !important;
          margin-left: 2rem !important;
          margin-right: 2rem !important;
          .sbi-screenreader {
            position: absolute;
            top: 0;
            left: 0;
            text-indent: inherit !important;
            width: 100% !important;
            height: 100% !important;
            line-height: 1.9rem !important;
            font-size: 1.4rem !important;
            padding-top: 0.9rem;
            padding-bottom: 0.9rem;
            padding-right: 1.5rem;
            padding-left: 1.2rem;
            opacity: 0;
            @include defaultTrans;
            &:hover {
              background-color: rgba(255,255,255,0.85);
              opacity: 1;
              @include defaultTrans;
            }
          }
          svg.svg-inline--fa.fa-clone.fa-w-16.sbi_lightbox_carousel_icon {
            display: none !important;
          }
          @media only screen and (max-width: 1024px) {
            width: 33% !important;
          }
          @media only screen and (max-width: 480px) {
            width: 50% !important;
          }

        }
        @media only screen and (max-width: 1024px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
        #sbi_images {
          margin: 0 auto;
        }
      }
    }
  }
}