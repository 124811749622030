.elementor-widget.image-slider {
  margin: 0 auto;
  margin-bottom: 8rem;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 4rem;
  }
  .widget-content {
    margin-top: 0;
  }
  ul {
    margin-bottom: 0;
    li{
      position: relative;
      > &:not(:first-child) {
        display: none;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include flexCenter;
        flex-direction: column;
        flex-wrap: wrap;
        h3 {
          z-index: 1;
          width: 100%;
          font-size: 8.9rem;
          line-height: 12.7rem;
          color: white;
          margin-bottom: 6rem;
          @media only screen and (max-width: 1024px) {
            font-size: 6rem;
            line-height: 8rem;
            margin-bottom: 4rem;
          }
          @media only screen and (max-width: 768px) {
            font-size: 3.8rem;
            line-height: 4.8rem;
            margin-bottom: 3.7rem;
          }
          @media only screen and (max-width: 768px) {
            max-width: 29rem;
            margin: 0 auto;
            margin-bottom: 3.5rem;
          }
        }
        a.action.toexternal {
          z-index: 1;
          background-color: white;
          color: black;
          border-color: white;

          &:hover {
            box-shadow: 0 0 1px 1px #fff;
          }
        }
        &:before {
          display: none;
          content: '';
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
  &.content {
    max-width: $smallContent;

  }
}