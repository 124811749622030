/*Product List*/
.product-slider {
  margin-bottom: 19rem;
  .widget-content{
    max-width: 134rem;
    margin: 0 auto;
  }
  .products.wrapper.grid.products-grid {
    .products.list {
      flex-wrap: nowrap;
      .product-item {
        max-width: inherit;
        margin-left: 0rem;
        @media only screen and (max-width: 1024px) {
          .product.details {
            .name {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

.products.products-grid {
  margin-bottom: 12rem;
  margin: 0 auto;
  .products.list {
    width: 100%;
    padding: 0;
    margin-bottom: 9rem;
    .product-item {
      margin-bottom: 3rem;
      position: relative;
      padding-bottom: 2rem;
      border: 0.1rem solid $colorGrey;
      .product.image {
        .product.photo {
          span {
            padding-bottom: 0 !important;
          }
        }
      }
      .product.details {
        text-align: center;
        .name {
          text-transform: uppercase;
          line-height: 2.5rem;
          letter-spacing: 0.2rem;
          padding-left: 2rem;
          padding-right: 2rem;
        }
        .product-item-price {
          @include defaultTrans;
          line-height: 2.9rem;
          padding-top: 1.3rem;
        }
        .spice-type {
          @include defaultTrans;
          line-height: 2.5rem;
        }
        .product-item-category a {
          @include fontSmaller;
          line-height: 2rem;
        }
        .price-details {
          @include fontSmallest;
          line-height: 1.5rem;
          padding-top: 3.8rem;
        }
        .product.actions.product-item-actions {
          position: absolute;
          bottom: 4rem;
          left: 0;
          right: 0;
          .actions-primary {
            padding-top: 1.3rem;
          }
        }
        button {
          opacity: 0;
          margin-right: 0 !important;
          margin: 0.2rem;
          @include defaultTrans;
        }
      }
      &:hover {
        border: 0.1rem solid $colorLightGrey;
        .product.details button {
          opacity: 1;
          @include defaultTrans;
        }
        .price, .spice-type {
          opacity: 0;
          @include defaultTrans;
        }
      }
      img {
        max-width: 100%;
      }
    }
  }
}