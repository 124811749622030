.elementor-widget.icon-list.layout01 {
  background-color: $colorGrey;
  margin-bottom: -12rem;
  ul {
    @include flexCenter;
    li {
      padding-top: 11rem;
      padding-bottom: 8rem;
      max-width: 27rem;
      margin-left: 3.7rem;
      margin-right: 3.7rem;
      text-align: center;
      img {
        max-width: 14.5rem;
        margin-bottom: 2.7rem;
      }
      h3 {
        margin-bottom: 3.4rem;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: -6rem;
    .widget-content {
      ul {
        flex-direction: column;
        li {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          img {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {

  }
  @media only screen and (max-width: 480px) {

  }
}
.elementor-widget.icon-list.layout02 {
  background-color: $colorWhite;
  margin-bottom: -12rem;
  padding-bottom: 8rem;
  ul {
    @include flex;
    justify-content: space-between;
    padding-left: 5rem;
    padding-right: 5rem;
    li {
      max-width: 15.5%;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.6rem;
      img {
        margin-bottom: 0.8rem;
      }
      h3 {
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: 0;
    padding-bottom: 0rem;
    ul {
      flex-wrap: wrap;
      li {
        max-width: 33%;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    ul {
      li {
        max-width: 50%;
      }
    }
  }
  @media only screen and (max-width: 480px) {

    ul {
      li {
        max-width: 100%;
      }
    }
  }
}