footer.page-footer {
  max-width: $contentWidth;
  margin: 0 auto;
  @include fontSmaller;
  font-weight: bold;
  @media only screen and (max-width: 480px) {
    font-size: 1.3rem;
  }
  a, h3 {
    text-align: left;
    @include fontSmaller;
    @media only screen and (max-width: 480px) {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
  h3 {
    padding-bottom: 1rem;
  }
  .footer-top {
    .footer-top-advantages {
      background-color: $colorPrimary;
      padding-left: 5rem;
      padding-right: 5rem;
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      ul {
        @include flexBetween;
        li {
          @include flex;
          align-items: center;
          i {
            font-size: 2.2rem;
          }
          span {
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: bold;
            padding-left: 2rem;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        ul {
          flex-wrap: wrap;
          justify-content: center;
          li {
            width: 100%;
            text-align: center;
            padding-bottom: 1.5rem;
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }

  }
  .footer-main {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    .footer-main-container {
      @include flex;
      flex-wrap: wrap;
      li {
        line-height: 2.2rem;
        a {
          line-height: 2.2rem;
          font-weight: normal;
        }
      }
      .section.footer-main-menu-payment{
        ul {
          @include flex;
          flex-wrap: wrap;
          li.shipping {
            margin-bottom: 1.5rem;
            width: 50%;
            img{
              max-height: 1.5rem;
            }
            @media only screen and (max-width: 480px) {
              width: 100%;
            }
          }
        }
      }
      .section.footer-main-menu-shipping{
        ul {
          @include flex;
          flex-wrap: wrap;

          li {
            img{
              max-height: 1.9rem;
            }
          }
        }
      }
      .section.footer-main-social {
        padding-top: 4.1rem;
        width: 100%;
        ul {
          @include flex;
        }
        @include fontSmaller;
        a {
          color: black;
          padding: 0;
        }
        i {
          font-size: 2rem;
          padding-right: 2rem;
        }
      }
      .section.footer-main-menu-company {
        width: 17.16417910447761%;
      }
      .section.footer-main-menu-service {
        width: 33.40298507462687%;
      }
      .section.footer-main-menu-payment {
        width: 25.74626865671642%;
      }
      .section.footer-main-menu-shipping {
        width: 7.597015%;
      }
      .section.footer-main-menu-gastro {
        width: 15.964179%;
        text-align: right;
        a {
          font-weight: normal;
          font-size: 2rem;
          line-height: 2.9rem;
          margin-right: -1rem;
        }
      }
    }
    @media only screen and (max-width: 1280px) {
      .footer-main-container {
        justify-content: space-between;
        .section.footer-main-menu-service {
          width: 20%;
        }
        .section.footer-main-menu-gastro {
          width: 18%;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      .footer-main-container {
        justify-content: space-between;
        .section.footer-main-menu-service, .section.footer-main-menu-company, .section.footer-main-menu-service, .section.footer-main-menu-shipping {
          width: 50%;
          margin-bottom: 3rem;
        }
        .section.footer-main-menu-gastro {
          width: 100%;
          text-align: left;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 0;
      .footer-main-container {
        position: relative;
        .section.footer-main-social{
          padding-top: 0;
          a {
            font-size: 2.2rem;
          }
        }
      }
      .section {
        width: 50%;
        padding-bottom: 3rem;
      }
      .section.footer-main-menu-gastro {
        position: absolute;
        bottom: 0;
        width: inherit;
        right: 1.7rem;
        text-align: right !important;

      }
    }
  }
  .footer-bottom {
    padding-left: 5rem;
    padding-right: 5rem;
    border-top: 1px solid #C8C8C8;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    .footer-bottom-container {
      .section {
        ul {
          @include flexBetween;
          flex-wrap: wrap;
          li {
            @media only screen and (max-width: 768px) {
              width:100%;
            }
            a {
              text-decoration: underline;
            }
          }
        }
      }

    }
  }
}
.footer-top-qualities {
  padding:1rem 0 4rem;
  background: $colorGrey;
  ul{
    @include flex;
    justify-content: space-between;
    padding-left: 5rem;
    padding-right: 5rem;

    li{
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.6rem;
      max-width: 15.5%;
      h3{
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
      p{
        font-weight: normal;
      }
    }
  }
}
