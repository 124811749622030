.block-search {
  @include flex;
  flex-direction: row-reverse;
  .mst-searchautocomplete__close {
    display: none;
  }
  .mst-searchautocomplete__wrapper {
    position: absolute;
    background: #F4F4F4;
    z-index: 101;
    width: 100%;
    left: 0;
    padding-left: 5rem;
    padding-bottom: 4.2rem;
    padding-top: 2rem;
    @media only screen and (max-width: 1280px) {
      padding-left: 3.3rem;
      padding-right: 3.3rem;
      width: 100%;
    }
    @include fontSmaller;
    font-weight: bold;
    @media only screen and (max-width: 1024px) {
      padding-left: 3.3rem;
      padding-right: 3.3rem;
    }

    @media only screen and (max-width: 480px) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
    .mst-searchautocomplete__show-all {
      position: absolute;
      right: 5rem;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1280px) {
        left: 0;
        right: 0;
        bottom: 1rem;
        justify-content: center;
        top: inherit;
      }
      a {
        text-decoration: underline;
      }
    }

    .mst-searchautocomplete__index.magento_catalog_product {
      ul {
        @include flex;
        margin-left: -2rem;
        margin-right: -2rem;
        li {
          margin-left: 2rem;
          margin-right: 2rem;
          text-align: center;
          @media only screen and (max-width: 1280px) {
            margin-left: 1rem;
            margin-right: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }
          img {
            max-width: 14rem;
            @media only screen and (max-width: 1280px) {
              max-width: 10rem;
            }
          }
          .title {
            a {
              text-transform: uppercase;

              @media only screen and (max-width: 1280px) {
                width: 100%;
                display: block;
                line-height: 2rem;
              }
            }
          }
          .price-box.price-final_price {
            display: none;
          }
        }
      }
    }
  }
  .block {

    &.block-title {
      cursor: pointer;
      strong {
        display: none;
      }
      &:before {
        background-image: url($iconDir + "search.svg");
        @include icon;
        width: 2.4rem;
        display: block;
        }
    }
    &.block-content {
      cursor: pointer;
      @include flex;
      flex-direction: row;
      display: none;
      .label {
        display: none;
      }
      .field.search {
        width: 97%;
      }
      .form {
        display: block;
        background: #F4F4F4;
        display: none;
        z-index: 101;
        width: calc(100% + 10rem);
        left: -5rem;
        max-width: 144rem;
        position: absolute;
        right: 0;
        bottom: -7.2rem;
        margin: 0 auto;
        padding-left: 4.8rem;
        padding-right: 4.8rem;
        padding-bottom: 2rem;
        padding-top: 2.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          border: none;
          height: inherit;
          box-shadow: none;
          background-color: transparent;
          width: 100%;
          border-bottom: 1px solid black;
          &::placeholder {
            opacity:1;
            font-size: 2rem;
            line-height: 2.9rem;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .actions {
        button.action.search {
          background-image: url($iconDir + "arrow_big.svg");
          height: 1.4rem;
          width: 1.7rem;
          border: none;
        }
        span {
          display: none;
        }
      }

    }
  }
  @media only screen and (max-width: 1280px) {
    .block.block-content .form {
      width: 100%;
      left: 0;
      padding-left: 3.3rem;
      padding-right: 3.3rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .block.block-content .form {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  &.active {
    .block.block-title {
      &:before {
        background-image: url($iconDir + "close_small.svg");
        @include icon;
        width: 2.4rem;
        display: block;
      }
    }
    .block-content {
      display: block;
    }
  }
}