@import "utils/variables";
@import "utils/helper";
@import "base/default";
@import "base/typography";



@media only screen and (max-width: 1600px) {
  .single {
    .post-view {
      margin-left: 5rem;
      margin-right: 5rem;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .products.wrapper.grid.products-grid, widget.elementor-widget.product-grid {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
@media only screen and (max-width: 1280px) {
  .widget-content.slider-text, .widget-content.text-slider, .checkout-container, .cart-container, .message, .page-layout-2columns-left .column, .account .columns .sidebar-main, .elementor-widget.newsletter-teaser, .widget.elementor-widget.contact-form, .product-teaser-community .widget-content, .product-teaser .widget-content, .page-wrapper .breadcrumbs, .page-wrapper .columns .main .category-selection, .page-header .header-content .header-main, .page-header .header-content .header-top, footer.page-footer .footer-top .footer-top-advantages, footer.page-footer .footer-bottom, .customer-account-login .column.main, .customer-account-create .column.main, footer.page-footer .footer-main {
    padding-left: 3.3rem;
    padding-right: 3.3rem;
  }
  .page-wrapper .columns .main .toolbar, .elementor-widget.instagram-feed, .page-wrapper .columns .main .toolbar.toolbar-products, .widget.magento-widget.category-related, .elementor-widget.tag-slider, .widget-title, .single .post-view, .page-wrapper .columns .main .products.products-grid {
    margin-left: 3.3rem;
    margin-right: 3.3rem;
  }
  .page-header .header-content .header-main .header-main-container .section-item-content ul li > a {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  button.splide__arrow.splide__arrow--next {
    right: 3.3rem;
  }
  button.splide__arrow.splide__arrow--prev {
    right: 9.3rem;
  }
  .page-layout-2columns-left .page-wrapper .page-main .products.products-grid {
    padding-left: 0;
    padding-right: 0;
    margin-right: 3.3rem;
    width: 100%;
  }
  .cart-container {
    flex-direction: column-reverse;
  }
  .login-container {
    flex-wrap: wrap;
  }
  .cart-container .cart-summary, .cart-container form.form-cart, .login-container .block {
    width: 100%;
    order: 2;
  }
  .page-layout-2columns-left .page-wrapper .page-main .columns {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 1024px) {
  button.splide__arrow.splide__arrow--next {
    right: inherit !important;
    left: 51% !important;
  }
  button.splide__arrow.splide__arrow--prev {
    right: 51% !important;
  }
  .counter.qty {
    top: 0 ;
  }
  .page-wrapper .columns {
    flex-direction: column-reverse;
    margin-bottom: 6rem;
  }
  .page-layout-2columns-left .column {
    width: 100%;
  }
  .page-wrapper {
    margin-top: 10rem;
  }
  .page-header {
    position: absolute;
  }

  h1 {
    padding-bottom: 2rem;
  }
  .page-wrapper .columns .main .category-selection .widget-content ul {
    flex-wrap: wrap;
    li {
      width: 33%;
      a {
        margin-bottom: 3rem;
        span {
          width: 8rem;
          height: 8rem;
          margin-bottom: 1rem;
          &:before {
            width: 2.8rem !important;
            height: 2.8rem !important;
          }
        }
      }
    }
  }
  .page-header .header-content .header-main .header-main-container > div {
    width: inherit;
  }
  .section.header-main-info {
    max-width: 5rem !important;
  }
  .page-main .actions-toolbar {
    display: block;
    text-align: center;
    .primary, .secondary {
      width: 100%;
      button, a {
        width: 25rem;
        margin: 0 auto;
        margin-bottom: 1rem;
        display: block;
      }
    }
  }
  .cart-container form.form-cart table tbody .actions-toolbar {
    display: flex;
  }
  .cart-container form.form-cart table tbody tr td.col.item a {
    padding-left: 0;
    padding-right: 2rem;
   }
  span.product-image-wrapper {
    padding-bottom: 0 !important;
  }
  body.checkout-index-index .checkout-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .opc-wrapper {
      order: 3;
      #payment .payment-method._active .payment-method-content {
        padding-left: 0;
      }
      li#payment {
        padding-top: 1.5rem;
      }
    }
  }
  .opc-summary-wrapper .modal-inner-wrap .opc-block-summary span.title {
    margin-top: 1.5rem !important;
  }
  .checkout-container .opc-summary-wrapper .modal-inner-wrap .opc-block-shipping-information .shipping-information-title {
    margin-bottom: 1rem;
  }
  .modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 90%;
    width: 95%;
    margin-left: 0;
    left: 0;
    right: 0;
    margin: 5rem auto;
  }
  .widget-title .title-link {
    display: none;
  }
  .page-wrapper .columns .main .category-selection {
    padding-bottom: 6rem;
  }
  ul.splide__pagination {
    bottom: 2rem;
  }
  body.account {
    .page-wrapper {
      .columns {
        .column.main {
          .table-wrapper {
            table {
              tbody {
                display: block;
                padding-bottom: 1.5rem;
              }
              th {
                display: none;
              }
              tr {
                display: block;
                td {
                  width: 100% !important;
                }
              }
            }
            .table:not(.cart):not(.totals):not(.table-comparison)>tbody>tr td[data-th]:before, .table:not(.cart):not(.totals):not(.table-comparison)>tbody>tr th[data-th]:before {
              padding-right: 10px;
              content: attr(data-th) ": ";
              display: inline-block;
              color: #111;
              font-weight: 700;
            }
            .table:not(.cart):not(.totals):not(.table-comparison)>tbody>tr td, .table:not(.cart):not(.totals):not(.table-comparison)>tbody>tr td {
              border-bottom: none;
              display: block;
              padding: 3px 0;
              text-align: left;
            }
          }
          .page-title-wrapper .page-title {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
  .footer-top {
    &-qualities {
      ul{
        flex-wrap: wrap;
        li{
          max-width: 33%;
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }

    }
  }
}
@media only screen and (max-width: 768px) {
  .elementor-widget.text {
    max-width: 100%;
  }
  body {
    font-size: 1.7rem;
    line-height: 2.6rem;
  }
  .elementor-widget.text-teaser {
    margin-bottom: 2.5rem;
  }
  .elementor-widget.image-slider.content, .elementor-widget.text-list, .page-wrapper .columns .main .page-title-wrapper .page-title, .elementor-widget.text {
    padding-left: 3.3rem;
    padding-right: 3.3rem;
  }

  .post-template-default ul.post-tags {
    padding-bottom: 2rem;
  }
  .elementor-widget.text, .elementor-widget.text-list:only-of-type {
    margin-bottom: 4rem;
  }

  .block-giftregistry-shared-items .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo {
    position: static;
  }


  td.col.qty, .cart-wrapper .col.qty, .cart.table-wrapper .col.msrp, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal {
    width: 33%;
  }
  .cart.table-wrapper .col.msrp[data-th]:before, .cart.table-wrapper .col.price[data-th]:before, .cart.table-wrapper .col.qty[data-th]:before, .cart.table-wrapper .col.subtotal[data-th]:before {
    padding-bottom: 3px;
    content: attr(data-th) ":";
    display: block;
    font-weight: 700;
  }
  body.checkout-cart-index {
    &.page-layout-1column {
      .cart-container {
        form.form-cart {
          table {
            tbody {
              .actions-toolbar {
                display: flex;
                justify-content: center;
                margin-top: 0;
                a {
                  margin-left: 2.5rem;
                  margin-right: 2.5rem;
                  padding: 0;
                }
              }
              tr {
                display: flex;
                justify-content: center;
                position: relative;
                flex-wrap: wrap;
                text-align: center;
                font-size: 1.4rem;

                td.col.item {
                  display: flex;
                  position: relative;
                  padding-bottom: 0;
                  width: 100%;
                  justify-content: center;
                  flex-direction: column;
                  text-align: center;
                  align-items: center;
                  .product-item-details {
                    padding-bottom: 1.5rem;
                  }
                }
              }
              td {
                padding: 1rem;
                text-align: center;
              }
            }
            thead {
              .col:not(.item) {
                display: none;
              }
            }
            td.col.item {
              a {
                display: block;
                max-width: 100%;
                padding-right: 0;
                position: static;
                vertical-align: top;
                width: 100%;
              }
            }
          }
          .actions {
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 0;
            margin-top: 1.5rem;
            margin-bottom: 2.5rem;
          }

          .cart.main.actions {
            .action {
              margin-left: 1rem;
              margin-right: 1rem;
              margin-bottom: 1rem;
            }
          }
        }
      }
      img.product-image-photo {
        margin: 0 auto;
      }
    }
    button.action.secondary.action-hide-popup {
      font-size: 1.7rem;
      line-height: 2.4rem;
    }
  }.widget.elementor-widget.text.full.left .widget-title {
     margin-left: 0;
   }
  .footer-top {
    &-qualities {
      ul{
        li{
          max-width: 50%;
        }
      }

    }
  }
}
@media only screen and (max-width: 480px) {
  .footer-top {
    &-qualities {
      ul{
        li{
          max-width: 100%;
        }
      }

    }
  }
  ul.splide__pagination {
    display: none;
  }
  .control._with-tooltip .input-text {
    flex-basis: 90%;
  }
  .widget-content.slider-text, .widget-content.text-slider, .checkout-container, .cart-container, .message, .page-layout-2columns-left .column, .account .columns .sidebar-main, .elementor-widget.newsletter-teaser, .widget.elementor-widget.contact-form, .page-wrapper .breadcrumbs, .page-wrapper .columns .main .category-selection, .page-header .header-content .header-main, .page-header .header-content .header-top, footer.page-footer .footer-top .footer-top-advantages, footer.page-footer .footer-bottom, .customer-account-login .column.main, .customer-account-create .column.main, footer.page-footer .footer-main {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .page-wrapper .columns .main .toolbar, .elementor-widget.instagram-feed, .page-wrapper .columns .main .toolbar.toolbar-products, .widget.magento-widget.category-related, .elementor-widget.tag-slider, .widget-title, .single .post-view, .page-wrapper .columns .main .products.products-grid {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
  .elementor-widget.text-teaser.left .widget-content, .elementor-widget.image-slider.content, .elementor-widget.text-list, .page-wrapper .columns .main .page-title-wrapper .page-title, .elementor-widget.text {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .page-wrapper .columns .main .category-selection .widget-content ul {
    li {
      width: 50%;
    }
  }
  .widget.magento-widget.category-overview .widget-content {
    padding-bottom: 8rem;
  }
}

