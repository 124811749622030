.elementor-widget.newsletter-teaser, .widget.elementor-widget.contact-form {
  margin: 0 auto;
  background-color: $colorGrey;
  padding: 5.8rem;
  margin-bottom: 10rem;
 label {
   display: none;
 }
  input, textarea {
    background-color: transparent;
    border: 0px;
    border-bottom: 2px solid black;
    color: black;
    font-size: 2rem;
    text-align: center;
    line-height: 2.9rem;
    &::placeholder {
      opacity:1;
      @include flex;
      align-items: flex-end;
      color: rgba(0,0,0,0.3);
      font-size: 2rem;
      text-align: center;
      line-height: 2.9rem;
    }
  }
  ul {
    @include flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    li {
      margin-bottom: 3rem;
    }
    li:nth-child(2), li:nth-child(3) {
      width: 48%;
      @media only screen and (max-width: 480px) {
        width: 100%;
      }
    }
    li:nth-child(4), li:nth-child(1):not(.rm-success-container) {
      width: 100%;
    }
    li:nth-child(5){
      margin: 0 auto;
      margin-top: 2rem;
    }
    .message {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: inherit;
      textarea {
        width: 100%;
        max-height: 15rem;
      }

    }
    input.wpcf7-submit {
      @include defaultFastTrans;
      @include flex;
      cursor: pointer;
      align-items: center;
      padding-top: 0.5rem;
      padding-bottom: 0.6rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 2.3rem;
      margin-right: 2rem;
      color: $colorBlack;
      background-color: transparent;
      border: 0.1rem solid black;
      margin: 0.1rem;
      display: inline-block;
      text-align: center;
      align-items: flex-start;
      @include defaultFastTrans;
      &:hover {
        box-shadow: 0 0 1px 1px $colorBlack;
      }
      @media only screen and (max-width: 768px) {
        font-size: 1.7rem !important;
        line-height: 2.4rem !important;
      }
    }

    .wpcf7-not-valid-tip {
      margin: 0.5rem 0 0;
      content: attr(data-hasrequired);
      color: $colorPrimary;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

  }
  .wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output {
    background: $colorPrimary;
    color: $colorWhite;
    border: none;
    margin: 0;
  }
  .wpcf7 form.sent .wpcf7-response-output {
    background: $colorSuccess;
    color: $colorWhite;
    border: none;
    margin: 0;
  }
}