.cart-container {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 5rem;
  @include flexStart;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  div#gift-options-cart {
    display: none;
  }
  form.form-cart {
    width: calc(100% - 39rem);
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        tr {
          &:first-child {
            text-align: left;
          }
        }
        th {
          padding-top: 0;
          padding: 0 2rem 1.1rem 1rem;
          border-bottom: 0.1rem solid black;
          text-align: center;
          &.subtotal {
            text-align: right;
            padding-right: 0;
          }
          &.item {
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 1rem 2rem 1.1rem 1rem;
            text-align: center;
            &.col.item {
              a {
                display: table-cell;
                max-width: 80%;
                padding-right: 4rem;
                position: static;
                vertical-align: top;
                width: 1%;
              }

              .product-item-details {
                display: table-cell;
                padding-bottom: 3.5rem;
                vertical-align: top;
                white-space: normal;
                width: 99%;
                dl.item-otions {
                  margin-top: 2rem;
                }
              }
            }

            &.col.price {
              display: table-cell;
              vertical-align: top;
            }

            &.col.qty {
              display: table-cell;
              vertical-align: top;
              text-align: center;
              .label {
                display: none;
              }

              input {
                width: 6rem;
              }
            }

            &.col.subtotal {
              display: table-cell;
              vertical-align: top;
              text-align: right;
              padding-right: 0;
            }
            input {
              width: inherit;
            }
            a {
              display: table-cell;
              max-width: 80%;
              padding-left: 2rem;
              position: static;
              vertical-align: top;
            }
          }
        }
        span.cart-price {
          font-weight: bold;
          @include fontSmall;
        }
        .item-actions {
          td {
            padding: 0;
          }
        }
        .actions-toolbar {
          border-bottom: 0.1rem solid $colorLighterGrey;
          text-align: right;
          padding-bottom: 1rem;
          justify-content: flex-end;
          margin-top: -4.5rem;
          .towishlist span {
            display: none;
          }
        }
        &.cart.item {
          border-bottom: 0.1rem solid black;
        }
      }
    }
    .cart.main.actions {
      padding-top: 2rem;
      @include flexEnd;
      .action {
        margin-left: 2rem;
      }

    }
  }
  .block.shipping {
    display: none;
  }
  caption.table-caption {
    display: none;
  }
  .cart-summary {
    width: 35rem;
    .summary.title {
      display: block;
      width: 100%;
      @include fontLarge;
      font-weight: bold;
      border-bottom: 0.1rem solid $colorLighterGrey;
      padding-bottom: 1rem;
    }
      button, .primary {
        width: 100%;
      }

    .cart-totals {
      border-bottom: 0.1rem solid $colorLighterGrey;
      padding-bottom: 1rem;
      .table-wrapper {
        .data.table.totals {
          width: 100%;
          .table-caption {
            text-align: right;
          }
          .totals-tax {
            padding-top: 1rem;
          }
          th, td {
            border-bottom: 0.1 solid $colorBlack;
            padding: 0;
            @include fontSmall;
            font-weight: normal;

          }
          th.mark {
            text-align: left;
          }
          td.amount {
            text-align: right;
          }
        }

        .load.indicator {
          background-color: rgba($colorLighterGrey, 0.5);
          z-index: 9999;
          bottom: 0;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat
            50% 50%;
            border-radius: 0.5rem;
            height: 16rem;
            width: 16rem;
            bottom: 0;
            box-sizing: border-box;
            content: "";
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
          }

          > span {
            display: none;
          }
        }

        .loading-mask {
          bottom: 0!important;
          left: 0!important;
          margin: auto;
          position: fixed!important;
          right: 0!important;
          top: 0!important;
          z-index: 100;
          background: rgba($colorLighterGrey, 0.5);

          .loader > {
            img {
              bottom: 0;
              left: 0;
              margin: auto;
              position: fixed;
              right: 0;
              top: 0;
              z-index: 100;
            }

            p {
              display: none;
            }
          }
        }

        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }
        [data-role="main-css-loader"] {
          display: none;
        }
      }

    }
    .block.discount {
      cursor: pointer;
      margin-bottom: 1.5rem;
      padding: 1.5rem;
      background-color: #F4F4F4;
      .field {
        margin-bottom: 1.5rem;
        label.label {
          display: none;
        }
      }
      .primary {
        margin: 0 auto;
      }
    }
  }
}