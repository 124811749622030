gg.elementor-widget.image {
  max-width: 88rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  ul {
    display: flex;
  }
  &.multiple {
    max-width: 134rem;
    ul {
      width: 100%;
      @include flexBetween;
      flex-wrap: wrap;
      li {
        max-width: 65rem;
        &:nth-of-type(n+3) {
          margin-top: 4rem;
        }
      }
    }
  }
}

.post-template-default {
    .elementor-widget.image {
        margin-top: 6rem;
    }
}
