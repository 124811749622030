  .mage-error {

  color: $colorPrimary;
    padding-top: 0.5rem;
}
  .message {
    max-width: 144rem;
    margin-top: 4rem;
    padding: 1rem;
    display: block;
    margin: 0 auto;
    position: relative;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    &.error {
      background: $colorError;
      color: $colorWhite;
    }
    &.success {
      background: $colorSuccess;
      color: $colorWhite;
    }
    &.info {
      background: $colorPrimary;
      color: $colorWhite;
    }
    &.empty {
      background: $colorPrimary;
      color: $colorWhite;
    }
    &.warning {
      background: $colorPrimary;
      color: $colorWhite;
    }
    &.notice {
      background: $colorPrimary;
      color: $colorWhite;
    }
    &.success,
    &.notice,
    &.warning,
    &.info,
    &.empty,
    &.error {
      a {
        color: $colorWhite;
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
        &:active {
          color: $colorWhite;
          text-decoration: underline;
        }
      }
    }
  }
  .field-error, .mage-error {
    color: $colorError;
    @include fontSmaller;
  }