.elementor-section-boxed {
  .widget.elementor-widget.image-slider-text.content {
    margin-bottom: 4rem;
  }
  .widget-content.slider-text, .widget-content.text-slider {
    @include flex;
    margin-top: 0;
    max-width: 134rem;
    margin: 0 auto;

    .slider, .text {
      width: 50%;
      h2 {
        padding-top: 3rem;
        margin-bottom: 5rem;
      }
      a.action {
        margin-top: 2rem;
      }
    }
    @media only screen and (max-width: 1280px) {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      .slider, .text {
        width: 100%;
        .splide__slide {
          text-align: center;
        }
      }
      &.text-slider {
        margin-top: 2.5rem;
        flex-direction: column-reverse;
      }
    }

  }
  .widget-content.text-slider {
    .splide__slide {
      text-align: right;
    }
    .text {
      padding-right: 3rem;
    }
  }
  .widget-content.slider-text {
    .text {
      padding-left: 3rem;
    }
  }
}