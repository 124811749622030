.checkout-cart-index {

  .cart-empty {
    padding-left: 5rem;
    padding-right: 5rem;
    text-align: center;
  }
}
.checkout-index-index * {
  outline: none;
}
.checkout-index-index {
  .page-main {
    padding-top: 0;
  }
  .header-main {
    min-height: 11.3rem;
   @include flexCenter;
  }
  .page-wrapper .columns .main .page-title-wrapper {
    padding-top: 0;
  }
  .step-title {
    margin-bottom: 2.5rem !important;
  }
}
.checkout-container {
  @include flexWrap;

  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5rem;
  padding-right: 5rem;
  .opc-progress-bar {
    padding-bottom: 5rem;
    padding-top: 5rem;
    width: 100%;
    display: flex;
    counter-reset: i;
    li {
      margin: 0 0 1rem;
      display: inline-block;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 50%;
      &:before {
        background: rgba(0, 0, 0, 0.4);
        top: 1.9rem;
        content: "";
        height: 0.3rem;
        left: 0;
        position: absolute;
        width: 100%;
      }
      &:first-child:before {
        border-radius: 0.6rem 0 0 0.6rem;
      }
      &:last-child:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }
      > span {
        display: inline-block;
        padding-top: 4.5rem;
        width: 100%;
        word-wrap: break-word;
        color: $colorBlack;
        font-weight: 300;
        font-size: 1.8rem;
        &:after {
          left: 50%;
          position: absolute;
          margin-left: -1.3rem;
          top: 0.3rem;
          width: 2.6rem;
          content: counter(i);
          counter-increment: i;
          background: white;
          padding: 3px;
          border-radius: 50%;
          border: 3px solid rgba(0, 0, 0, 0.4);
          display: inline-block;
          height: 3rem;
          width: 3rem;
          font-size: 3rem;
          color: rgba(0, 0, 0, 0.4);
          content: '';
          height: 3.9rem;
          width: 3.9rem;
          background-size: 70%;
          content: counter(i);
          counter-increment: i;
        }
      }
      &._active {
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorBlack;
          &:after {
            padding: 3px;
            border-radius: 50%;
            border: 3px solid black;
            background-image: url($iconDir + "check.svg");
            @include icon;
            height: 3.9rem;
            width: 3.9rem;
            background-size: 70%;
          }
        }
      }
      &._complete {
        cursor: pointer;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorBlack;
          &:after {
            padding: 3px;
            border-radius: 50%;
            border: 3px solid black;
            color: black;
            background-image: url($iconDir + "check.svg");
            @include icon;
            height: 3.9rem;
            width: 3.9rem;
            background-size: 70%;
          }
        }
      }
    }
  }
  .authentication-wrapper {
    .action-auth-toggle {
      background: none;
      padding: 0;
      border: none;
      color: $colorPrimary;
      @include fontLarge;
      &:hover {
        opacity: 0.5;
      }
    }
    .authentication-dropdown {
      position: absolute;
      background: $colorWhite;
      border: 1px solid $colorGrey;
      margin-top: 0.5rem;
      max-width: 1500px;
      width: 100%;
      z-index: 101;
      box-shadow: 0 0.3rem 0.3rem $colorGrey;
      padding: 2.5rem 2rem;
      visibility: hidden;
      &._show {
        z-index: 100;
        transform: scale(1, 1);
        transition: transform linear 0.1s, visibility 0s linear 0s;
        visibility: visible;
      }
      .modal-inner-wrap {
        display: flex;
        flex-direction: column;
        .modal-header {
          button {
            display: inline-block;
            background-image: none;
            background: none;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-decoration: none;
            text-shadow: none;
            font-weight: 400;
            height: 4rem;
            position: absolute;
            right: 0;
            top: 0;
            width: 4rem;
            > span {
              border: 0;
              clip: rect(0, 0, 0, 0);
              height: 0.1rem;
              margin: -0.1rem;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 0.1rem;
            }
            &:before {
            }
            &.disabled {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        }
        .modal-content {
          text-align: left;

          .block-title {
            margin: 1rem 0;
          }
          .control {
            margin: 1rem 0;
            display: flex;
            flex-wrap: wrap;
            input {
              width: 100%;
            }
          }
          .actions-toolbar {
            padding-top: 1rem;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
  .opc-estimated-wrapper {
    display: none;
  }
  .messages {
    grid-column: 1 / 3;
    grid-row: 3;
  }
  .opc-wrapper {
    width: 74%;
    ol#checkoutSteps {
      list-style: none;
      .shipping-address-item {
        margin-bottom: 1.5rem;
      }
    }
    .new-address-popup {
      margin-bottom: 2.5rem;
    }
    .control._with-tooltip {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      .input-text {
        flex-basis: 94%;
      }
      .field-tooltip {
        cursor: pointer;
        &.toggle {
          #tooltip-label {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
          #tooltip {
            &:before {
              @include fontIcon;
              font-size: 2rem;
              color: $colorBlack;
              content: "#{$iconQuestion}";
            }
          }
        }
        &._active .field-tooltip-content {
          display: block;
        }
        .field-tooltip-content {
          background: $colorLighterGrey;
          border: 0.1rem solid $colorLighterGrey;
          border-radius: 0.1rem;
          font-size: 1.4rem;
          padding: 1.2rem;
          width: 27rem;
          display: none;
          margin-left: 4rem;
          position: absolute;
          text-transform: none;
          top: -0.9rem;
          word-wrap: break-word;
          z-index: 2;
          &:before, &:after {
            border: 1rem solid transparent;
            height: 0;
            width: 0;
            border-right-color: $colorLighterGrey;
            left: -2.1rem;
            top: 1.2rem;
            content: "";
            display: block;
            position: absolute;
            z-index: 3;
          }
          &:before {
            border-right-color: $colorGrey;
          }
          &:after {
            border-right-color: $colorLighterGrey;
            width: 0.1rem;
            z-index: 4;
          }
        }
      }
      .note {
        flex-basis: 100%;
        margin-top: 0.5rem;
        color: $colorBlack;
      }
    }
    .control {
      margin: 1rem 0;
      input {
        width: 100%;
      }
    }
    .step-title {
      border-bottom: 0.1rem solid $colorBlack;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      @extend h3;
      text-align: left;
    }
    .checkout-billing-address {
      margin-bottom: 2rem;
      .billing-address-details {
        br {
          display: block;
        }
      }
      .field-select-billing {
        margin-bottom: 2rem;
      }
      .billing-address-same-as-shipping-block {
        margin-bottom: 1rem;
        margin-top: 1rem;
        @include flex;
        align-items: center;
        input {
          width: inherit;
          margin-right: 1rem;
        }
      }
      fieldset.fieldset {
        padding-bottom: 0;
        .control {
          margin: 0.5rem 0 1rem;
        }
      }
    }
    fieldset.field.street.admin__control-fields.required{
      margin-top: -1rem;
      legend {
        display: none;
      }
    }
    form fieldset {
      padding-bottom: 0;
    }
    li#shipping {
      .amazon-button-container {
        margin-bottom: 2rem;
      }
      form.form-login {
        padding-bottom: 3rem;
        margin-bottom: 2rem;
        border-bottom: 0.1rem solid $colorBlack;
      }
      .note {
        @include fontSmaller;
      }
    }
    li#opc-shipping_method{
      #checkout-shipping-method-load {
        table.table-checkout-shipping-method {
          width: 100%;
          border-collapse: collapse;
          .col {
            cursor: pointer;
            border-bottom: 0.1rem solid $colorBlack;
            padding: 1.5rem 0;
          }
          tr {
            &:last-child {
              .col {
                border-bottom: 0px;
              }
            }
          }
        }
      }
    }
    #payment {
      legend.legend {
        @extend h3;
        width: 100%;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        text-align: left;
        border-bottom: 0.1rem solid black;
      }
      .payment-method {
        margin-bottom: 0 !important;
        cursor: pointer;
        border-bottom: 0.1rem solid $colorBlack;
        padding: 1.5rem 0;
        .payment-method-content {
          display: none;

        }
        &._active {
          padding: 20px;
          padding-left: 0;
          padding-right: 0;
          .payment-method-title.field.choice {
            padding-left: 0;
          }
          .payment-method-content  {
            display: block;
            padding-top: 1.5rem;
            padding-left: 2rem;
            .mage-error {
              margin-left: 15px;
              font-size: smaller;
            }
          }
          background-color: $colorGrey;
        }

        .payment-method-title {
          font-weight: bold;
          width: 100%;
          @include flex;
          align-items: center;
          label {
            cursor: pointer;
            padding-left: 1rem;
          }
          input {
            width: 1.5rem;
          }
        }
        label button {
          background-color: transparent;
          color: black;
          font-weight: normal;
          padding: 0;
          line-height: 1;
          vertical-align: inherit;
          border: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .primary {
          width: 100%;
          margin-top: 1rem;
        }
        button.action.primary.checkout {
          width: 100%;
          margin-top: 10px;
        }
        .checkout-agreement.field.choice.required {
          padding-left: 0px;
          padding-top: 5px;
          @include flex;
          align-items: center;
          input {
            width: inherit;
            margin-right: 1rem;
            margin-left: 0;
          }
           label:after {
            top: -10px;
            right: -10px;
          }
        }
        .payment-method-title.field.choice label {
          cursor: pointer;
        }
      &:last-child {
        border-bottom: 0;
      }
     }
    }
    button.action.action-edit-address {
      margin-top: 1.5rem;
    }
  }
  #checkout-loader {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed !important;
    right: 0rem;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba($colorGrey, 0.75);
    .loader {
      img {
        max-width: 4.4rem;
        position: fixed;
        top: 25%;
        left: 50%;
        z-index: 9999;
      }
    }
  }
  .opc-summary-wrapper {
    width: 24%;
    padding: 1.5rem;
    padding-top: 0;
    .modal-inner-wrap {

      header {
        display: none;
      }
      .opc-block-summary {

        span.title {
          @extend h3;
          border-bottom: 0.1rem solid black;
          margin-bottom: 2rem;
          padding-bottom: 1rem;
          display: block;
          text-align: left;
        }
        table {
          width: 100%;
          margin-top: 1rem;
          caption.table-caption {
            display: none;
          }
          th {
            text-align: left;
          }
          td {
            text-align: right;
          }
          tr.grand.totals {
            padding-top: 0.5rem;
          }
        }
        .block.items-in-cart {
          margin-top: 1rem;
          cursor: pointer;
          .title {
            padding-bottom: 1rem;
            margin-bottom: 2rem;
            position: relative;
            &:after {
              color: $colorGrey;
              background-image: url($iconDir + "arrow_small.svg");
              @include icon;
              width: 1.7rem;
              height: 1.7rem;
              float: right;
              position: absolute;
              right: 0;
              bottom: 2rem;
              @include defaultTrans;
            }

          }
          &.active .title{
            &:after {
              transform: rotate(90deg);
              @include defaultTrans;
            }
          }
          ol.minicart-items {
            list-style: none;
            li.product-item {
              padding-bottom: 2rem;
              margin-bottom: 2rem;
              border-bottom: 0.1rem solid black;
              &:last-child {
                border: none;
              }
              .product {
                display: flex;
                .product-item-details {
                  margin-left: 2rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  @include fontSmall;
                  line-height: 2rem;
                  .product-item-inner {
                    height: 100%;
                    .product-item-name-block {
                      padding-bottom: 1rem;
                    }
                  }
                }
              }
            }
          }
        }

      }
      .opc-block-shipping-information {
        .shipping-information-title {
          border-bottom: 1px solid rgba(0,0,0,0.4);
          margin-bottom: 2rem;
          padding-bottom: 1rem;
          @include flexBetween;
          button {
            background-color: transparent;
          }
        }
        .ship-to {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .opc-progress-bar, .opc-summary-wrapper{
      width: 100%;
      padding: 0;
    }
    .opc-wrapper {
      width: 100%;
      div#checkout-shipping-method-load{
        padding-top: 0rem;
        padding-bottom: 0rem;
      }
      div#shipping-method-buttons-container .primary, div#checkout-payment-method-container .primary {
        margin: 0 auto !important;
        margin-bottom: 2.5rem !important;
      }
      .field.addresses, .new-address-popup {
        text-align: left;
        button {
          min-width: 25rem;
        }
      }
      .new-address-popup {
        text-align: center;
      }
      .step-title {
        margin-bottom: 1rem !important;
      }
      #payment .payment-method:last-child {
        padding-bottom: 0;
      }
    }

  }

  div#registration {
    .action {
      display: block;
      max-width: 20rem;
      margin-top: 1.5rem;
    }
  }
  li#opc-shipping_method {
    margin-top: 2.5rem;
  }
  div#checkout-shipping-method-load, div#checkout-payment-method-load {
    padding: 2rem;
    background-color: #F4F4F4;
    margin-bottom: 2.5rem;
  }
  div#shipping-method-buttons-container,  div#checkout-payment-method-container{
    justify-content: flex-end;
    .primary {
      margin-right: 0 !important;
    }
  }
  div#checkout-shipping-method-load {
    td.col.col-method:first-child {
      width: 1.5rem;
    }
    td.col.col-method:last-child {
      padding-left: 1rem !important;
    }
  }

}



/*Modal*/

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.modal-slide, .modal-popup {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}
.checkout-index-index .modal-popup .modal-inner-wrap {
  left: 50%;
  margin-left: -400px;
  width: 800px;
}

.modal-slide._show, .modal-popup._show {
  visibility: visible;
}

.modal-slide._show .modal-inner-wrap, .modal-popup._show .modal-inner-wrap {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-slide .modal-inner-wrap, .modal-popup .modal-inner-wrap {
  background-color: #fff;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, .35);
  opacity: 1;
  pointer-events: auto;
}

.modal-slide {
  left: 44px;
  z-index: 900;
}

.modal-slide._show .modal-inner-wrap {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.modal-slide .modal-inner-wrap {
  -webkit-transform: translateX(100%);
  -webkit-transition-property: -webkit-transform, visibility;
  height: 100%;
  overflow-y: auto;
  position: static;
  transform: translateX(100%);
  transition-duration: .3s;
  transition-property: transform, visibility;
  transition-timing-function: ease-in-out;
  width: auto;
}

.modal-slide._inner-scroll .modal-inner-wrap {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
}

.modal-slide._inner-scroll .modal-header, .modal-slide._inner-scroll .modal-footer {
  -webkit-flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-grow: 0;
  flex-shrink: 0;
}

.modal-slide._inner-scroll .modal-content {
  overflow-y: auto;
  h3 {
    text-align: left;
    padding-top: 2rem;
  }
}

.modal-slide._inner-scroll .modal-footer {
  margin-top: auto;
}

.modal-slide .modal-header, .modal-slide .modal-content, .modal-slide .modal-footer {
  padding: 0 2.6rem 2.6rem;
}

.modal-slide .modal-header {
  padding-bottom: 2.1rem;
  padding-top: 2.1rem;
}

.modal-popup {
  left: 0;
  overflow-y: auto;
  z-index: 900;
}

.modal-popup._show .modal-inner-wrap {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.modal-popup .modal-inner-wrap {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-transform: translateY(-200%);
  -webkit-transition-property: -webkit-transform, visibility;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: auto;
  left: 0;
  margin: 5rem auto;
  position: absolute;
  right: 0;
  transform: translateY(-200%);
  transition-duration: .2s;
  transition-property: transform, visibility;
  transition-timing-function: ease;
  width: 75%;
}

.modal-popup._inner-scroll {
  overflow-y: visible;
}

.ie10 .modal-popup._inner-scroll, .ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
}

.modal-popup._inner-scroll .modal-inner-wrap {
  max-height: 90%;
}

.ie10 .modal-popup._inner-scroll .modal-inner-wrap, .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
  max-height: none;
}

.modal-popup._inner-scroll .modal-content {
  overflow-y: auto;
}
.modal-popup .modal-header, .modal-popup .modal-footer {
  -webkit-flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-grow: 0;
  flex-shrink: 0;
}

.modal-popup .modal-header {
  padding-bottom: 1.2rem;
  padding-top: 2rem;
}

.modal-popup .modal-footer {
  margin-top: auto;
  padding-bottom: 2rem;
  padding-top: 0rem;
}

.modal-popup .modal-footer-actions {
  text-align: right;
}

.modal-custom .action-close, .modal-popup .action-close, .modal-slide .action-close {
  -moz-box-sizing: content-box;
  background: none;
  background-image: none;
  border: 0;
  box-shadow: none;
  display: inline-block;
  font-weight: 400;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  text-shadow: none;
  top: 0;
}

.modal-custom .action-close:focus, .modal-popup .action-close:focus, .modal-slide .action-close:focus, .modal-custom .action-close:active, .modal-popup .action-close:active, .modal-slide .action-close:active {
  background: none;
  border: none;
}

.modal-custom .action-close:hover, .modal-popup .action-close:hover, .modal-slide .action-close:hover {
  background: none;
  border: none;
}

.modal-custom .action-close.disabled, .modal-popup .action-close.disabled, .modal-slide .action-close.disabled, .modal-custom .action-close[disabled], .modal-popup .action-close[disabled], .modal-slide .action-close[disabled], fieldset[disabled] .modal-custom .action-close, fieldset[disabled] .modal-popup .action-close, fieldset[disabled] .modal-slide .action-close {
  cursor: not-allowed;
  opacity: .5;
  pointer-events: none;
}

.modal-custom .action-close>span, .modal-popup .action-close>span, .modal-slide .action-close>span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.modal-custom .action-close:before, .modal-popup .action-close:before, .modal-slide .action-close:before {
  -webkit-font-smoothing: antialiased;
  color: #000;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  margin: 0;
  overflow: hidden;
  speak: none;
  text-align: center;
  vertical-align: top;
}

.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
  color: inherit;
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
  color: #000;
}

.modal-custom .action-close {
  margin: 25px;
}

.modal-popup .modal-title {
  border-bottom: 1px solid #c1c1c1;
  font-size: 26px;
  font-weight: 300;
  margin-bottom: 0;
  min-height: 1em;
  padding-bottom: 10px;
  word-wrap: break-word;
}
div#newsletter-error {
  padding: 10px;
}
.modal-popup .action-close {
  padding: 20px;
}

.modal-slide .action-close {
  padding: 2.1rem 3rem;
  text-align: right !important;
}

.modal-slide .page-main-actions {
  margin-bottom: -12.9rem;
  margin-top: 2.1rem;
}

.modals-overlay {
  background-color: rgba(51, 51, 51, .55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 899;
}

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 899;
}
/*Modal Ende*/

/*Loaden*/
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
  p  {
    display: none;
  }
}

.load.indicator {
  background-color: rgba($colorGrey, 0.5);
  z-index: 9999;

  &:before {
    /*background: transparent url($image-dir + "loader.gif") no-repeat 50%
    50%;*/
    border-radius: 0.5rem;
    box-sizing: border-box;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  > span {
    display: none;
  }
}
html {
  position: relative ;
}
.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed !important;
  right: 0rem;
  top: 0;
  z-index: 9999;
  background: rgba($colorGrey, 0.75);
}

.loader {
  > img {
    max-width: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
}
.checkout-success .actions-toolbar {
  margin-top: 15px;
}
.checkout-success {
  text-align: center;
}
/*Ende*/



