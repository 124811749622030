*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-size: 2rem;
  line-height: 3.1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width:$maxWidth;
  margin:0 auto;
  color: $colorBlack;
}
.fal {
  font-weight: 400;
}

::selection {
  background-color: $colorPrimary;
  color: $colorWhite;
}

