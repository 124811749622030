.page-products.page-layout-2columns-left {
  .page-main {
    padding-top: 8.5rem;
    .columns {
      border-top: 1px solid #C8C8C8;

    }
  }
}
.products.wrapper.grid.products-grid, widget.elementor-widget.product-grid {
  max-width: 134rem;
  width: auto;
  margin: 0 auto;
  ol.product-items {
    @include flexWrap;
    width: auto;
    margin-bottom: 0;
    margin-left: -2rem;
    margin-right: -2rem;
    @media only screen and (max-width: 1024px) {
      margin-left: 0rem;
      margin-right: 0rem;
    }
    li.product-item {
      max-width: 30.5rem;
      margin-left: 2rem;
      margin-right: 2rem;
      width: 33%;
      @media only screen and (max-width: 1280px) {
        width: 50%;
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
      }
      img {
        max-width: 100%;
      }
      a {
        hyphens: auto;
      }
      &.available-soon {
        opacity:0.3;
      }
    }
  }
  &.three {
    .products.list.items.product-items {
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1280px) {
    ol {
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1024px) {

  }
  @media only screen and (max-width: 768px) {
  }
}