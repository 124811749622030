.home {
  .page-main {
    padding-top: 0;
  }
}
header, section, footer, aside, nav, main, article, figure {
  display: block;
}
/*Spide*/
.elementor-widget .widget-content .splide .splide__slide.is-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
}
.elementor-widget .widget-content .splide {
  visibility: visible !important;
}
ul.splide__pagination{
  bottom: 4rem;
  justify-content: center;
  li {
    button.is-active {
      background-color: white;
      border: 0.1rem solid white;
      border-radius: 50%;
      width: 0.9rem;
      height: 0.9rem;
      transform: none;
      &:before {
        color: transparent;
      }
      &:hover {
        background-color: white;
      }
    }
    button {
      border: 0.1rem solid white;
      margin-left: 1.4rem;
      margin-right: 1.4rem;
      transition: none;
      opacity: 1;
      background-color: transparent;
      border: 0.1rem solid white;
      border-radius: 50%;
      width: 0.9rem;
      height: 0.9rem;
      &:before {
        color: transparent;
      }

    }
  }
}

button.splide__arrow.splide__arrow--prev {
  width: 4rem;
  height: 4rem;
  right: 6rem;
  left: inherit;
  bottom: -7rem;
  top: inherit;
  background-image: url($iconDir + "arrow_left.svg");
  transform: rotate(180deg);
  background-color: transparent;
}
button.splide__arrow.splide__arrow--next{
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  right: 0rem;
  left: inherit;
  bottom: -9rem;
  top: inherit;
  background-image: url($iconDir + "arrow_right.svg");
}
button.splide__arrow.splide__arrow--next, button.splide__arrow.splide__arrow--prev {
  svg {
    display: none;
  }
}

button.slick-prev.slick-arrow {
  width: 4rem;
  height: 4rem;
  right: 6rem;
  left: inherit;
  bottom: -7rem;
  top: inherit;
  background-image: url($iconDir + "arrow_left.svg");
  transform: rotate(180deg);
  background-color: transparent;
  &:before {
    color: transparent;
  }
}
button.slick-next.slick-arrow{
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  right: 0rem;
  left: inherit;
  bottom: -9rem;
  top: inherit;
  background-image: url($iconDir + "arrow_right.svg");
  &:before {
    color: transparent;
  }
}

/*2 Col Layout*/
.page-layout-2columns-left {
  .column {
    width: calc(100% - 280px);
  }
  .page-wrapper .columns {
    margin-top: 0px;
  }
  .page-wrapper .page-main .products.products-grid {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid $colorLightGrey;
    margin-left: -4rem;
    padding-left: 4rem;
    padding-right: 5rem;
    &:last-child {
      border-bottom: 0;
    }
  }
}

/*Account Page*/
.account .page-wrapper .page-main {
  .page-title-wrapper {
    padding-top: 3rem;
    h1 {
      text-align: left !important;
    }
  }
  .columns .column {
    width: calc(100% - 280px);
  }
}

/*Page*/
.page-main {
  padding-top: 8.5rem;
}
.page-wrapper {
  margin-top: 19rem;

  /*Breadcrumbs*/
  .breadcrumbs {
    padding-left: 5rem;
    padding-top: 3rem;
    padding-bottom: 3.5rem;
    max-width: $contentWidth;
    margin: 0 auto;
    color: $colorSecondary;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: -8.5rem;
    li {
      display: inline;
      &:first-child a{
        margin-left: 0;
      }
      a, strong {
        display: inline;
        margin: 0 1rem;
        @media only screen and (max-width: 768px) {
          margin: 0 0.4rem;
        }
      }
      strong {
        opacity: 1;
        font-weight: normal;
      }
      a {
        opacity: 0.3;
      }
    }
    li + li::before {
      content: '/';
    }
    @media only screen and (max-width: 768px) {
      font-size: 1.1;
    }
  }
  .block.block-addresses-list + div {
    display: flex;
  }
  /*Columns*/
  .columns {
    max-width: $contentWidth;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 12rem;
    position: relative;
    @include flexBetween;
    flex-direction: row-reverse;
    .main {
      .columns {
        @include flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        .column {
        }
      }
      /*Toolbar*/
      .toolbar {
        margin-bottom: 3rem;
        margin-left: 5rem;
        margin-right: 5rem;
        &.customer-addresses-toolbar {
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 2rem;
          text-align: right;
          margin: 0;
        }
      /*Toolbar Sorter*/
        .toolbar-sorter {
          @include fontSmaller;
          font-weight: bold;
          line-height: 2rem;
          text-align: right;
          text-decoration: underline;
          @include flexEnd;
          align-items: center;
          z-index: 1;
          .sorter-label {
            @include flex;
            justify-content: flex-end;
          }
          select {
          }
          .sorter-action {
            display: none;
          }
          select {
            width: inherit;
            cursor: pointer;
            border: 0;
            @include fontSmaller;
            font-weight: bold;
          }
        }
        .pages {
          display: none;
          > strong {
            display: none;
          }
          ul {
            @include flex;
            align-items: center;
            justify-content: center;
            li a, li strong {
              padding-left: 1.5rem;
              padding-right: 1.5rem;
            }

            li span.label {
              display: none;
            }
            li.item.pages-item-previous a {
              height: 0.8rem;
              width: 0.8rem;
                background-image: url($iconDir + "arrow_small.svg");
              background-repeat: no-repeat;
              transform: rotate(180deg);
              span {
                display: none;
              }
            }
            li.item.pages-item-next a {
                height: 0.8rem;
                width: 0.8rem;
                background-image: url($iconDir + "arrow_small.svg");
              background-repeat: no-repeat;

              span {
                display: none;
              }
            }
            li.current {
              span {
                text-align: center;
                width: 2.1rem;
                height: 2.1rem;
                border-radius: 2.3rem;
                border: 1px solid black;
                font-weight: normal;
              }
            }
            a {
              display: flex;
              align-items: center;
            }
            span {
              font-size: 1.4rem;
              line-height: 2rem;

            }
            li strong {
              @include flex;
            }
          }
        }
      }
      .products-grid + .toolbar-products .pages, .posts-grid + .toolbar-posts .pages{
        display: block;
        margin-bottom: -3rem;
        + .toolbar-sorter {
          display: none;
        }
      }
      .products-grid + .toolbar-products .toolbar-sorter {
        display: none;
      }
      .products-grid + .toolbar-products {
        margin-top: 3rem;
      }
      .posts-grid + .toolbar-posts {
        margin-top: 3rem;
      }
      /*Category*/
      .category-selection {
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 12rem;
        .widget-content {
          ul {
            @include flexBetween;
            li {
              @include flexCenter;
              a {
                @include flexCenter;
                flex-direction: column;
                &:before {
                  display: none;
                }
                span {
                  margin-bottom: 2rem;
                  @include flexCenter;
                  width: 11.6rem;
                  border-radius: 50%;
                  height: 11.6rem;
                  border: 0.1rem solid $colorGrey;
                }
              }
            }
          }
          .question-mark {
            span {
              @include defaultTrans;
              &:before {
                @extend a.surprise;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorGrey;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorGrey;
              }
            }
          }
          .packs {
            span {
              &:before {
                @extend a.packs;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorSixPack;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorSixPack;
              }
            }
          }
          .vinegar-oil {
            span {
              &:before {
                @extend a.vinegar-oil;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorVinegar;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorVinegar;
              }
            }
          }
          .spices {
            span {
              &:before {
                @extend a.spices;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorHerbs;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorHerbs;
              }
            }
          }
          .spice-mixtures {
            span {
              &:before {
                @extend a.spice-mixtures;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorCurrys;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorCurrys;
              }
            }
          }
          .salts {
            span {
              &:before {
                @extend a.salts;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorPuresalt;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorPuresalt;
              }
            }
          }
          .sauces-soups {
            span {
              &:before {
                @extend a.sauces-soups;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorSoups;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorSoups;
              }
            }
          }
          .organic-assortment {
            span {
              &:before {
                @extend a.organic-assortment;
              }
              @media only screen and (max-width: 1024px) {
                background-color: $colorBio;
              }
            }
            &:hover {
              span {
                @include defaultTrans;
                background-color: $colorBio;
              }
            }
          }
        }
      }
      .category-image {
        @include flexCenter;
        margin-bottom: 6rem;
        img {
          width: 100%;
          max-width: 134rem;
          margin: 0 auto;
        }
      }
      .category-description {
        padding-bottom: 3rem;
      }
      /*Page Title*/
      .page-title-wrapper {
        .page-title {
          text-align: center;
        }
      }

      /*old & special Price*/
      .price-final_price {
        @include flexCenter;
        .percentage {
          color: $colorPrimary;
          @include fontSmaller;
          margin-right: 1.5rem;
        }
        .special-price {
          color: $colorPrimary;
          .price-label {
            display: none;
          }
        }
        .old-price {
          padding-left: 1.6rem;
          @include flexCenter;
          text-decoration: line-through;
          span {
            @include fontSmaller;
          }
          .price-label {
            display: none;
          }
        }
      }
    }
  }
  .block.filter {
    display: none;
  }
  .page-sharing-wrapper {
    position: absolute;
    top: -3rem;
    right: 0;
  }
}

.product-grid.three {
  .products.list {
    justify-content: center !important;
  }
}
.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
  padding: 0 !important;
}
.error404 {
  .page-info-main {
    text-align: center;
  }
}
.widget.magento-widget.category-overview {
  a.action.tocategory.secondary {
    border-color: #000;
    color: #000;
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    &:hover {
      box-shadow: 0 0 1px 1px #000;
    }
  }
}
.wishlist-index-index {
  .page-wrapper {
    .columns {
      .main {
        .products.products-grid {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 4.5rem;
          margin-bottom: 2rem;
          margin-left: 0;
          margin-right: 0;
          .products.list {
            margin-bottom: 0.5rem;
          }
        }
        .actions-toolbar > .primary {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
.widget-content {
  margin-top: -5rem;
}
.widget-title {
  position: relative;
  max-width: 134rem;
  margin: 0 auto;
  margin-bottom: 8rem;
  .title-link {
    text-align: right;
    display: block;
    @include fontSmaller;
    font-weight: bold;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: -8rem;
  }
}
.column {
  width: 100%;
}

.customer-account-logoutsuccess, .archive {
  .main {
    text-align: center;
  }
}

.privacy-policy {
  .widget.elementor-widget.text-teaser.small.left {
    margin-bottom: 0;
  }
}
.elementor-widget:not(:last-child) {
  margin-bottom: 0px !important;
}
/*Pagination*/