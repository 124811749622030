body.page-layout-2columns-left .products.wrapper.grid.products-grid {
  .products.header {
    padding-top: 5rem;
    @include flexBetween;
    padding-bottom: 3.3rem;
    ul {
      @include flex;
      li {
        a {
          margin-left: 2rem;
          border-color: #C8C8C8;
          color: black;
          &:hover {
            box-shadow: 0 0 1px 1px #C8C8C8;
          }
        }
      }
    }

  }
  @media only screen and (max-width: 1280px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 1024px) {
    .products.header {
      margin-right: 0;
      margin-top: 4rem;
      text-align: center;
      justify-content: center;
    }
    ul.categories.list {
      display: none;
    }
  }
}
.page-products {
  .message {
    max-width: 134rem;
    margin: 0 auto;
  }
}