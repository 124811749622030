/*Produkt Teaser*/
.elementor-section.elementor-section-boxed>.elementor-container {
  max-width: inherit !important;
}
.product-teaser .widget-content{
  @include flex;
  margin: 0 auto;
  margin-bottom: 12rem;
  padding-top: 4.5rem;
  padding-bottom: 8rem;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 98rem;
  justify-content: space-between;
  .product-teaser-background {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    top: 0;
    &:before {
      content: '';
      z-index: -1;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
      background-color: $colorGrey;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.herbs {
      &:before {
        background-color: $colorHerbs;
      }
    }
    &.chilis {
      &:before {
        background-color: $colorChilis;
      }
    }
    &.rawspices {
      &:before {
        background-color: $colorRawspices;
      }
    }
    &.pepper {
      &:before {
        background-color: $colorPepper;
      }
    }
    &.bbqdown, &.bbq {
      &:before {
        background-color: $colorBBQdown;
      }
    }
    &.bbqup {
      &:before {
        background-color: $colorBBQup;
      }
    }
    &.currys {
      &:before {
        background-color: $colorCurrys;
      }
    }
    &.near-far {
      &:before {
        background-color: $colorNearfar;
      }
    }
    &.sweets {
      &:before {
        background-color: $colorSweets;
      }
    }

    &.saltspice {
      &:before {
        background-color: $colorSaltspice;
      }
    }
    &.puresalt {
      &:before {
        background-color: $colorPuresalt;
      }
    }
    &.soups {
      &:before {
        background-color: $colorSoups;
      }
    }
    &.sauces {
      &:before {
        background-color: $colorSauces;
      }
    }
    &.bio {
      &:before {
        background-color: $colorBio;
      }
    }
    &.oil{
      &:before {
        background-color: $colorOil;
      }
    }
    &.vinegar{
      &:before {
        background-color: $colorVinegar;
      }
    }
    &.sixpacks{
      &:before {
        background-color: $colorSixPack;
      }
    }
    &.trypacks{
      &:before {
        background-color: $colorTryPack;
      }
    }
  }
  .product-teaser-image {
    img {
      max-width: 30.5rem;
      width: 100%;
    }
  }
  .product-teaser-info {
    z-index: 1;
    .product-info-category {
      ul {
        @include flex;
        li {
          margin-right: 2rem;
          a.action.tocategory.secondary {
            border-color: #c8c8c8;
            color: black;
            &:hover {
              box-shadow: 0 0 1px 1px #c8c8c8;
            }
          }
        }
      }
    }
    h3 {
      max-width: 42rem;
      @include fontxLargest;
      text-align: left;
      padding-bottom: 2.4rem;
      padding-top: 2rem;
    }
  }
  .product-teaser-meta {
    position: absolute;
    bottom: 3rem;
    left: 5rem;
    .product-info-category-main {
      li {
        a {
          display: flex;
          align-items: center;
          @include fontSmaller;
          font-weight: bold;
          line-height: 2rem;
          text-decoration: underline;
          span {
            padding-left: 2rem;
            display: block;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .product-teaser-meta {
      left: 3.3rem;
    }

  }
  @media only screen and (max-width: 1024px) {
    padding-top: 15rem;
    .product-teaser-meta {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .product-teaser-info {
      h3 {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
    padding-top: 5rem;
    flex-wrap: wrap;
    padding-bottom: 5rem;
    .product-teaser-isnew {
      &:after {
        width: 6.9rem !important;
        height: 6.9rem!important;
        left: inherit !important;
        font-size: 1.6rem !important;
        letter-spacing: 0.4rem !important;
        right: 2.5rem;
        border: 0.3rem solid black !important;
      }
    }
    .product-teaser-image {
      width: 100%;
      text-align: center;
    }
    .product-teaser-info {
      width: 100%;
      .info-wrapper {
        text-align: center;
      }
      .product-info-category {
        ul {
          justify-content: center;
          flex-wrap: wrap;
          li {
            margin-right: 0.6rem;
            margin-left: 0.6rem;
            margin-bottom: 1.2rem;
          }
        }
      }
      h3 {
        text-align: center;
        font-size: 2.4rem;
        line-height: 3.4rem;
        padding-top: 1.7rem;
        padding-bottom: 2rem;
        margin: 0 auto;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 8rem;
  }
}

.product-teaser {
  .product-teaser-background {
    &.curry {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        background-color: #FFDF54;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .product-teaser-isnew {
    &:after {
      top: 0;
      content: 'neu';
      font-size: 2.8rem;
      line-height: 4rem;
      text-transform: uppercase;
      font-weight: bold;
      width: 11.8rem;
      height: 11.8rem;
      border: 0.3rem solid black;
      border-radius: 50%;
      position: absolute;
      left: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1rem;
    }
  }
}

/*Produkt Teaser Community*/
.product-teaser-community .widget-content{
  @include flex;
  justify-content: space-between;
  min-height: 52rem;
  max-width: 134rem;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 12rem;
  .product-teaser-background {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    height: 100%;
    &:before {
      content: '';
      z-index: -1;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
      background-color: $colorGrey;
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0.95;
    }
    &.herbs {
      &:before {
        background-color: $colorHerbs;
      }
    }
    &.chilis {
      &:before {
        background-color: $colorChilis;
      }
    }
    &.rawspices {
      &:before {
        background-color: $colorRawspices;
      }
    }
    &.pepper {
      &:before {
        background-color: $colorPepper;
      }
    }
    &.bbqdown, &.bbq {
      &:before {
        background-color: $colorBBQdown;
      }
    }
    &.bbqup {
      &:before {
        background-color: $colorBBQup;
      }
    }
    &.currys {
      &:before {
        background-color: $colorCurrys;
      }
    }
    &.near-far {
      &:before {
        background-color: $colorNearfar;
      }
    }
    &.sweets {
      &:before {
        background-color: $colorSweets;
      }
    }

    &.saltspice {
      &:before {
        background-color: $colorSaltspice;
      }
    }
    &.pure-salts, &.pursalt {
      &:before {
        background-color: $colorPuresalt;
      }
    }
    &.soups {
      &:before {
        background-color: $colorSoups;
      }
    }
    &.sauces {
      &:before {
        background-color: $colorSauces;
      }
    }
    &.bio {
      &:before {
        background-color: $colorBio;
      }
    }
    &.oil{
      &:before {
        background-color: $colorOil;
      }
    }
    &.vinegar{
      &:before {
        background-color: $colorVinegar;
      }
    }
    &.sixpacks{
      &:before {
        background-color: $colorSixPack;
      }
    }
    &.trypacks{
      &:before {
        background-color: $colorTryPack;
      }
    }


  }
  .product-teaser-user {
    font-weight: bold;
    @include flexCenter;
    justify-content: flex-start;
    text-align: center;
    ul {
      text-align: center;
    }
    img {
      border-radius: 50%;
      max-width: 11.5rem;
    }
  }
  .product-teaser-image {
    @include flexCenter;
    min-height: 52rem;
    img {
      max-width: 30.5rem;
      width: 100%;
    }
  }
  .product-teaser-info {
    z-index: 1;
    @include flexCenter;
    z-index: 1;
    .product-info-category {
      ul {
        @include flex;
        li {
          margin-right: 2rem;
          a {
            color: white;
          }
        }
      }
    }
   a {
     color: white;
     border-color: white;
     &:hover {
       box-shadow: 0 0 1px 1px white;
     }
   }
    h4 {
      color: white;
      max-width: 65rem;
      @include fontLarger;
      text-align: left;
      padding-bottom: 2.4rem;
      padding-top: 2rem;
    }
    a.action.toexternal.secondary {
      border-color: white;
      color: white;
      &:hover  {
        box-shadow: 0 0 1px 1px #fff;
      }
    }
  }
  .product-teaser-meta {
    position: absolute;
    bottom: 3rem;
    left: 5rem;
    .product-info-category-main {
      li {
        a {
          display: flex;
          align-items: center;
          @include fontSmaller;
          font-weight: bold;
          line-height: 2rem;
          text-decoration: underline;
          span {
            padding-left: 2rem;
            display: block;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .product-teaser-meta {
      left: 3.3rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    .product-teaser-background {
      background-size: cover;
      background-position: center center;
    }
    .product-teaser-user {
      width: 100%;
      padding-top: 3rem;
      padding-bottom: 3rem;
      justify-content: center;
      text-align: center;
      color: white;
    }
    .product-teaser-image, .product-teaser-info {
      width: 50%;
      padding-bottom: 4rem;
    }
    .product-teaser-meta {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .product-teaser-info {
      h3 {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
    .product-teaser-user {
      padding-top: 4rem;
    }
    .product-teaser-isnew {
      &:after {
        width: 6.9rem !important;
        height: 6.9rem!important;
        left: inherit !important;
        font-size: 1.6rem !important;
        letter-spacing: 0.4rem !important;
        right: 2.5rem;
        border: 0.3rem solid black !important;
      }
    }
    .product-teaser-image {
      width: 100%;
      text-align: center;
    }
    .product-teaser-info {
      width: 100%;
      padding-bottom: 5rem;
      .info-wrapper {
        text-align: center;
      }
      .product-info-category {
        ul {
          justify-content: center;
          flex-wrap: wrap;
          li {
            margin-right: 0.6rem;
            margin-left: 0.6rem;
            margin-bottom: 1.2rem;
          }
        }
      }
      h4 {
        text-align: center;
        font-size: 2.4rem;
        line-height: 3.4rem;
        padding-bottom: 2rem;
      }
    }
  }
}
