.elementor-widget.icon-teaser {
  max-width: 88rem;
  margin: 0 auto;
  .widget-content {

    ul {
      @include flexBetween;
      padding-bottom: 10rem;
      li {
        @include flex;
        flex-direction: column;
        max-width: 19rem;
        width: 100%;
        text-align: center;
        align-items: center;
        img {
          max-width: 11.6rem;
          padding-bottom: 2rem;
        }
        h3 {
          line-height: 2.9rem;
        }
      }
    }
  }
}
