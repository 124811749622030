.minicart-wrapper {
  &.active {
    overflow: visible;
    .block-minicart {
      display: block;
      background: $colorGrey;
      margin-top: 0.5rem;
    }
  }
  /*Miniwarenkorb Symbol*/
  .action.showcart {
    cursor: pointer;
    @include flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &:before {
      background-image: url($iconDir + "basket.svg");
      @include icon;
      width: 2.4rem;
    }
    .subtotal {
      font-size: 1.1rem;
      line-height: 1.6rem;
      font-weight: bold;
      text-decoration: underline;
      padding-top: 0.5rem;
    }
    &:hover {
      &:before {
        color: $colorPrimary;
      }
    }
    &:active {
      &:before {
        color: $colorLighterGrey;
      }
    }
    .counter.qty {
      font-weight: bold;
      @include fontSmaller;
      position: absolute;
      top: 0.3rem;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
    span.text {
      display: none;
    }
  }

  /*Miniwarenkorb Content*/
  .block-minicart {
    display: none;
    z-index: 101;
    width: calc(100% + 10rem);
    left: -5rem;
    max-width: 144rem;
    position: absolute;
    right: 0;
    bottom: -7.2rem;
    margin: 0 auto;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    padding-bottom: 2rem;
    padding-top: 2.1rem;
    @include flexCenter;
    li {
      margin: 0;
      &:hover {
        cursor: pointer;
      }
    }
    #minicart-content-wrapper {
      .block-title {
        .qty {
          display: none;
        }
      }
      .block-content {
        .action.close {
          background: none;
          border: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          right: 1rem;
          top: 1rem;
          width: 1.2rem;
          > span {
            height: 0.1rem;
            overflow: hidden;
            position: absolute;
            width: 0.1rem;
          }

          &:before {
            background-image: url($iconDir + "close.svg");
            @include icon;
            width: 1.2rem;
            height: 1.2rem;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        .items-total {
          float: left;
          margin: 0;
          span.count {
            font-weight: bold;
          }
        }

        .subtotal {
          text-align: right;
          @include flexEnd;
          @include fontSmaller;
          .price-wrapper {
            padding-left: 0.5rem;
          }
        }
        .actions {
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          button#top-cart-btn-checkout {
            padding-top: 0rem;
            padding-bottom: 0rem;
          }
          .action {
            @include fontSmaller;
            font-weight: bold;
            text-decoration: underline;
            padding: 0;
            border: 0;
            margin-left: 4rem;
            margin-right: 0;
            &.delete, &.edit {
              border: none;
              padding: 0;
              margin: 0;
            }
          }
        }

        // items
        .minicart-items-wrapper {
          border: 0.1rem solid $colorBlack;
          margin: 1rem 0rem;
          border-left: 0;
          border-right: 0;
          padding-top: 1.5rem;
          ol {
            li{
              padding: 2rem 0;
              padding-bottom: 1rem;
              &:not(:first-child) {
                border-top: 0.1rem solid $colorBlack;
              }
              &:first-child {
                padding-top: 0;
              }
              .product {
                position: relative;
                @include flexStart;
                img.product-image-photo {
                  display: block;
                }
                .toggle {
                  display: block;
                  text-decoration: none;
                  cursor: pointer;
                  position: relative;
                  &:after {
                    color: $colorGrey;
                    content: "#{$iconClose}";
                    @include fontIcon;
                    position: static;
                    right: 2rem;
                    top: 0;
                  }
                }
                &.active {
                  > .toggle {
                    &:after {
                      content: "#{$iconClose}";
                    }
                  }
                }
                &-item-details {
                  padding-left: 1rem;
                  @include flexWrap;

                  .product-item-name {
                    @include fontSmall;
                    padding-bottom: 5px;
                  }
                  .price-wrapper {
                    @include fontSmall;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    display: block;
                  }
                  .price-including-tax {
                    margin: 0.5rem 0 0;
                    font-weight: bold;
                  }
                  .price-excluding-tax {
                    margin: 0.5rem 0 0;
                  }
                  .product.actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }
                &.options {
                  .tooltip.toggle {
                    display: inline-block;
                    text-decoration: none;

                    > span {
                      border: 0;
                      clip: rect(0, 0, 0, 0);
                      height: 0.1rem;
                      margin: -0.1rem;
                      overflow: hidden;
                      padding: 0;
                      position: absolute;
                      width: 0.1rem;
                    }

                    &:after {
                      color: $colorGrey;
                      content: "#{$iconClose}";
                      @include fontIcon;
                      margin: -0.3rem 0 0 0.7rem;
                    }

                    .details {
                      display: none;
                    }
                  }
                }
                &-item-pricing {
                  width: 100%;
                  .details-qty.qty {
                    @include flex;
                    input {
                      height: 3rem;
                      margin-left: 1rem;
                      width: 5rem;
                      padding: 0;
                      text-align: center;
                    }
                    button {
                      text-shadow: none;
                      border: 0;
                      color: $colorPrimary;
                      background-color: transparent;
                      font-size: 1.2rem;
                      font-weight: bold;
                      padding-left: 1rem;
                    }
                  }
                }
                &.actions {

                }
              }
            }
            .price-minicart {
              margin-bottom: 0.5rem;
            }
            .update-cart-item {
              font-size: 1.1rem;
              vertical-align: top;
            }
            .subtitle {
              display: none;
            }
          }
        }
      }
    }
  }
}