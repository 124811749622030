.elementor-widget.text {
  max-width: $smallContent;
  margin: 0 auto;
  margin-bottom: 6rem;
  &.full {
    width: 100%;
  }
  &.half {
    @include fontSmall;
    .content,h1,h2,h3,h4 {
      width: 50%;
      max-width: 30.5rem;
    }
  }
  &.left {
    text-align: left;
    h3 {
      text-align: left;
    }
  }
  &.right {
    .content,h1,h2,h3,h4 {
      text-align: left;
      margin-right: 0;
      margin-left: inherit;
    }
  }
  .widget-content {
    margin-top: 0;
  }
}