@font-face {
  font-family: 'Brandon';
  font-weight: normal;
  font-display: auto;
  src: url('../../fonts/BrandonGrotesqueWeb-Regular.eot'); /* IE9+ Compat Modes */
  src: url('../../fonts/BrandonGrotesqueWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/BrandonGrotesqueWeb-Regular.woff2') format('woff2'), /* Super modern browsers */
  url('../../fonts/BrandonGrotesqueWeb-Regular.woff') format('woff'), /* Modern browsers */
  url('../../fonts/BrandonGrotesqueWeb-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/BrandonGrotesqueWeb-Regular.svg#BrandonGrotesqueWeb-Regular') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Brandon';
  font-display: auto;
  font-weight: bold;
  src: url('../../fonts/BrandonGrotesqueWeb-Bold.eot'); /* IE9+ Compat Modes */
  src: url('../../fonts/BrandonGrotesqueWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/BrandonGrotesqueWeb-Bold.woff2') format('woff2'), /* Super modern browsers */
  url('../../fonts/BrandonGrotesqueWeb-Bold.woff') format('woff'), /* Modern browsers */
  url('../../fonts/BrandonGrotesqueWeb-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/BrandonGrotesqueWeb-Bold.svg#BrandonGrotesqueWeb-Bold') format('svg'); /* Legacy iOS */
}
@mixin fontIcon {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
@mixin fontDefault {
  font-family: 'Brandon', sans-serif;
  font-size: 2rem;
  color: $colorBlack;
  line-height: 3.1rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.4rem
  }
}

@mixin fontHeadline {
  font-family: 'Brandon', sans-serif;
  color: $colorBlack;
  margin: 0;
  padding: 0 0 0 0;
}
@mixin font20 {
  font-size: 2rem;
  line-height: 3.1rem;
  @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.4rem
  }
}
@mixin fontSmallest {
  font-size: 1rem;
  line-height: 1.4rem;
}
@mixin fontSmaller {
  font-size: 1.4rem;
}
@mixin fontSmall {
  font-size: 1.6rem;
  line-height: 2.6rem;
}
@mixin fontLarge {
  line-height: 3.7rem;
  font-size: 2.4rem;
}
@mixin fontLarger {
  font-size: 3.2rem;
  line-height: 4.5rem;
}
@mixin fontLargest {
  font-size: 4.2rem;
  line-height: 5.2rem;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
}
@mixin fontxLargest {
  font-size: 5.2rem;
  line-height: 7.2rem;
  font-weight: bold;
}

/*=============================================
=            Price Fonts            =
=============================================*/

@mixin fontPriceProducts {
  font-weight: bold;
}

@mixin fontPriceProductsSpecial {
  font-weight: bold;
  color: $colorSecondary;
}

@mixin fontPriceProductsSale {
  font-weight: bold;
  text-decoration: line-through;
}

@mixin fontPriceProductSale {
  font-weight: bold;
  text-decoration: line-through;
}

body {
  @include fontDefault;
}
h1, h2, h3, h4, h5, h6 {
  @include fontHeadline;
  text-align: center;
}
h1 {
  font-size: 5.2rem;
  font-weight: bold;
  line-height: 7.2rem;
  padding-bottom: 0.6rem;
  &:not(:first-child) {
    padding-top: 0rem;
  }
  &.noPadding {
    padding-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 3.4rem;
    line-height: 4.6rem;
  }
}
h2 {
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 4.5rem;
  margin-bottom: 8rem;
  &:not(:first-child) {
    padding-top: 0rem;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 5rem;
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
}
h3 {
    font-size: 2rem;
    line-height: 3.1rem;
    letter-spacing: 0;
    font-weight: bold;
  &:not(:first-child) {
    padding-top: 0rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
}
h4 {
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: bold;
}

a {
  color: $colorBlack;
  text-decoration: none;

}
b, strong {
  font-weight: bold;
}

ol, ul {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}
