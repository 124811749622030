/*Sidebar*/
.account .sidebar-main, .page-products .sidebar-main {
  width: 24rem;
  background-color: $colorGrey;
  order: 1;
}
.sidebar-main {
  padding-bottom: 5rem;
  .block.category {
    background-color: $colorGrey;
    margin-bottom: 2rem;
    .block-title.category-title {
      font-size: 3.2rem;
      text-align: center;
      line-height: 4.2rem;
      padding-top: 4rem;
      padding-bottom: 4rem;
      padding-left: 3rem;
      padding-right: 3rem;
      border-bottom: 1px solid $colorLightGrey;
      &:after {

      }
    }
    .block-content {
      .category-list.level-3 {
        .category-item.level-3 {
          border-bottom: 1px solid $colorLightGrey;
          min-height: 12.1rem;
          & > a {
            padding-top: 2.2rem;
            @include flexCenter;
            flex-direction: column;
            @include fontSmall;
            font-weight: bold;
            line-height: 2.3rem;
            &:before {
              height: 4.5rem;
            }
          }
          &.active {
            a {
              font-weight: bold;
            }
          }
          &.active ul.category-list.level-4 {
            max-height: 30rem;
            @include defaultTrans;
            padding-top: 2.4rem;
            padding-bottom: 3.8rem;
          }
          ul.category-list.level-4 {
            max-height: 0;
            padding-top: 0rem;
            padding-bottom: 0rem;
            overflow: hidden;
            @include defaultTrans;
          }
        }
        &.active {
          .category-item.level-3:not(.active){
            opacity: 30%;
          }
        }
      }
      ul.category-list.level-4 {
        padding-top: 2.4rem;
        padding-bottom: 3.8rem;
        @include defaultTrans;
        li {
          a {
            padding-bottom: 1.1rem;
            display: block;
            font-weight: bold;
            line-height: 2.9rem;
            text-align: center;
            margin-right: -0.8rem;
            &:after {
              background-image: url($iconDir + "arrow_small.svg");
              @include icon;
              height: 0.8rem;
              width: 1.6rem;
            }
            &:before {
              display: none;
            }
          }
          &:last-child a {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .page-products .sidebar{
    width: 100%;
    display: none;
  }
}