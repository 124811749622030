// width
$maxWidth: 192rem;
$contentWidth: 144rem;
$smallContent: 65rem;

// images
$imageDir: "../../images/";
// icons
$iconDir: "../../images/icons/";

// colors + shades
$colorWhite: #fff;
$colorBlack: #000;
$colorPrimary: #F3A598;
$colorSecondary: #000;
$colorGrey: #F4F4F4;
$colorLightGrey: #C8C8C8;
$colorBorder: #d1d1d1;
$colorError: #F3A598;
$colorSuccess: #B7D2B1;
$colorInfo: #F7B234;
$colorWarning: #F7B234;
$colorBusiness: #2b292c;
$colorButton: #e2001a;
$colorLighterGrey: lighten($colorLightGrey, 20%);

$colorChilis: #E84F35;
$colorHerbs: #b7d2b1;
$colorRawspices: #d4ba92;
$colorPepper: #b1ada5;
$colorBBQdown: #c84044;
$colorBBQup: #73726c;
$colorCurrys: #ffdf54;
$colorNearfar: #f49e3b;
$colorSweets: #f3a598;
$colorSaltspice: #8E9DC2;
$colorPuresalt: #dee9f4;
$colorSoups: #dfb04a;
$colorSauces: #946c4c;
$colorBio: #637b49;
$colorRosesalt: #ffcaef;
$colorOil: #cacd7f;
$colorVinegar: #b7928e;
$colorSixPack: #cec5ca;
$colorTryPack: #e3e0cc;


// icons: use fontawesome pro unicode - leave empty for no icon
$iconCart: "\f290";
$iconWishlisht: "\f004";
$iconCompare: "\f080";
$iconClose: "\f00d";
$iconTrash: "\f1f8";
$iconEdit: "\f304";
$iconGrid: "\f009";
$iconList: "\f03a";
$iconArrow: "\f077";
$iconPagination: "\f054";
$iconCheck: "\f00c";
$iconAsterix: "\f069";
$iconQuestion: "\f059";
$iconError: "\f06a";
$iconInfo: "\f05a";
$iconWarning: "\f071";
$iconToggle: "\f0c9";
$iconSearch: "\f002";
$iconSortAsc: "\f160";
$iconSortDesc: "\f161";
$iconKey: "\f084";
$iconPerson: "\f007";
