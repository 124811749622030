.post-template-default {
  #maincontent img {
    width: 100%;
  }
  .post-view {
    padding-left: 5rem;
    padding-right: 5rem;
    .post-image {
      @include flexCenter;
    }
    .post-date {
      padding-top: 2rem;
      @include fontSmall;
      text-align: right;
    }
  }
  .page-title-wrapper {
    max-width: $smallContent;
    margin: 0 auto;
    width: 100%;
  }
  ul.post-tags {
    padding-bottom: 4rem;
    @include flexCenter;
    li {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }
}

.is-blog {
  /*.toolbar.toolbar-posts:last-child {
    display: none;
  }*/
  .toolbar {
    /*padding-top: 5rem;
    @include flex;
    justify-content: flex-end;*/
    .toolbar-category {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      ul {
        @include flexCenter;
        li {
          a.action.tocategory.secondary {
            margin-left: 1rem;
            margin-right: 1rem;
            border-color: #C8C8C8;
            color: black;
            font-size: 1.4rem !important;
            line-height: 2rem !important;
            padding-top: 0.4rem !important;
            padding-bottom: 0.5rem !important;
            &:hover {
              box-shadow: 0 0 1px 1px #C8C8C8;
            }
          }
        }
      }

    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      .toolbar-category {
        padding-bottom: 3rem;
        position: inherit;
      }
    }
    @media only screen and (max-width: 480px) {
      padding-top: 1rem;
    }
  }
}
.single-post {
  .column.main {
    @media only screen and (max-width: 480px) {
      padding-left: 2rem;
      padding-right: 2rem;
      .post-view {
        margin: 0px;
      }
    }
  }
  .post-view {
    padding: 0;
    margin-left: 5rem;
    margin-right: 5rem;
    position: relative;
    .post-image {
      display: block;
      img {
        display: block;
      }
    }
    .post-date {
      @media only screen and (max-width: 1024px) {
        padding-top: 1rem;
        margin-bottom: 11.2rem;
      }
    }
    .overlay {
      position: absolute;
      left: 7.5rem;
      bottom: -12.7rem;
      font-size: 2rem;
      line-height: 2.9rem;
      font-weight: bold;
      @media only screen and (max-width: 1280px) {
        left: 3.3rem;
        bottom: -8.8rem;
      }
      @media only screen and (max-width: 480px) {
        left: 2rem;
      }
      .name, .link {
        padding-left: 4rem;
        padding-right: 3.2rem;
        @media only screen and (max-width: 1024px) {
          padding: 0;
        }
      }
      img {
        border-radius: 50%;
        max-width: 20rem;
        margin-bottom: 1.6rem;
        @media only screen and (max-width: 1024px) {
          max-width: 10rem;
        }
      }
    }
  }
}

.posts-grid {
  width: 100%;
  max-width: 134rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-bottom: 0.1rem solid #C8C8C8;
  margin-bottom: 2rem;

  ol.posts.list.items.post-items {
    width: auto;
    @include flexWrap;
    margin-left: -2rem;
    margin-right: -2rem;
    > li {
      max-width: 30.5rem;
      width: 33%;
      margin-bottom: 3.6rem;
      margin-left: 2rem;
      margin-right: 2rem;
      .post.image.post-item-image {
        position: relative;
        max-height: 42rem;
        img {
          width: 100%;
        }
        .overlay {
          top: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          max-width: 30.5rem;
          opacity: 0;
          @include defaultTrans;
          @include flexCenter;
          flex-direction: column;
          &:hover {
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.85);
            @include defaultTrans;
          }
          ul.post-tags {
            @include fontSmall;
            @include flexCenter;
            flex-direction: row;
            text-align: center;
            margin-bottom: 3rem;
            li {
              width: 100%;
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
          }
          h3 {
            margin-bottom: 3rem;
          }
          a {
            color: black;
            border-color: black;
            display: inline-flex;
            &:hover {
              box-shadow: 0 0 1px 1px black;
            }
          }
        }
      }
      .post.category.post-item-category {
        padding-top: 2.7rem;
        ul {
          @include flexCenter;
          li {
            width: inherit;
            margin-right: 0;
            margin-left: 0;
            @include fontSmall;
            a {
              @include fontSmall;
              margin-right: 0.5rem;
              &:after {
                content: ' · ';

              }
            }
            span {
            }
          }
        }
      }
      .post.name.post-item-name {
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 1600px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media only screen and (max-width: 1280px) {
    padding-left: 3.3rem;
    padding-right: 3.3rem;
  }
  @media only screen and (max-width: 1024px) {
    ol.posts.list.items.post-items {
      li.item.post.post-item {
        margin-right: 1rem;
        margin-left: 1rem;
        .post.details.post-item-details {
          padding-bottom: 2rem;
        }
        .post.category.post-item-category {
          padding-top: 2rem;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    ol.posts.list.items.post-items {
      flex-wrap: wrap;
      justify-content: center;
      margin-right: 0rem;
      margin-left: 0rem;
      li.item.post.post-item {
        width: 50%;
        margin-right: 2rem;
        margin-left: 2rem;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    ol.posts.list.items.post-items {
      li.item.post.post-item {
        width: 100%;
        margin-right: 0rem;
        margin-left: 0rem;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-top: 6rem;
  }
}

